import { CellClassParams } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import { ChangeEvent, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSplashMessage } from "src/hook/useSplashMessage";
import { ReplacePartsGetResponse } from "src/model/api/response/ReplacePartsGetResponse";
import { ProductCodeConst, TextConst } from "src/util/Constant";
import { formatOrderNo, getBizError } from "src/util/ecUtil";

import { useAuthUserContext } from "../../context/AuthUser";
import { useDialog } from "../../context/DialogProvider";
import useApi from "../../hook/useApi";
import { CompanyGetResponse } from "../../model/api/response/CompanyGetResponse";
import {
  agJapanCurrencyFormatter,
  agNumberFormatter,
  agProductNameFormatter,
  agYmdFormatterSlash,
  defaultColDef,
} from "../../util/AgGridUtil";
import PageSizeSelector from "../common/PageSizeSelector";
import { SearchValueType } from "./OrderAnalysisTop";

function OrderProductList(
  data: ReplacePartsGetResponse[] | null | undefined,
  searchValues: SearchValueType,
  query: string
) {
  // 翻訳
  const { t: tc } = useTranslation("OrderProductList");

  const FILE_NAME_START = tc(
    "部品交換実施リストFileName",
    "部品交換実施リスト"
  );
  const NO_VESSEL = tc("船舶無し");
  const START_MONTH_JAN = tc("(年)");
  const START_MONTH_APR = tc("(年度)");
  const VESSEL_TOTAL = tc("管理船舶合算");
  const SELECT_VESSEL_LIST = 0; //分析対象船舶で"船名リストから選択"を選択
  const ORDER_NO_TITLE = tc("注文番号");
  const DISCOUNT_TITLE = tc("割引額");
  const TAX_TITLE = tc("消費税額");

  /** 送料を表す品目コード群 */
  const DELIVERY_PRICE_CODES = ["7478000", "7478001"];

  // 参照
  const gridRef = useRef<AgGridReact>(null); // グリッド

  //スプラッシュメッセージ使用宣言
  const splashMessage = useSplashMessage();
  // API使用宣言
  const api = useApi();
  // ユーザ情報
  const auth = useAuthUserContext();
  //ダイアログ使用宣言
  const showDialog = useDialog();
  // メッセージ
  const { t } = useTranslation();

  // 表示チェックボックス
  const [showsDeliveryPrice, setShowsDeliveryPrice] = useState(false);
  const [showsExpenses, setShowsExpenses] = useState(false);
  const [showsOrderNo, setShowsOrderNo] = useState(false);
  const showSettings = [
    {
      text: tc("送料"),
      checked: showsDeliveryPrice,
      onChange: (e: ChangeEvent<HTMLInputElement>) =>
        setShowsDeliveryPrice(e.target.checked),
    },
    {
      text: tc("経費"),
      checked: showsExpenses,
      onChange: (e: ChangeEvent<HTMLInputElement>) =>
        setShowsExpenses(e.target.checked),
    },
    {
      text: tc("注文番号"),
      checked: showsOrderNo,
      onChange: (e: ChangeEvent<HTMLInputElement>) =>
        setShowsOrderNo(e.target.checked),
    },
  ];

  function getFilteredData() {
    if (!data) {
      return [];
    }

    const result = sortAndAddOrderRows([...data]);

    return result
      .filter(
        (row) => showsExpenses || row.productCode !== ProductCodeConst.EXPENSES
      )
      .filter(
        (row) =>
          showsDeliveryPrice || !DELIVERY_PRICE_CODES.includes(row.productCode)
      );
  }

  // 注文番号表示の場合
  function sortAndAddOrderRows(original: ReplacePartsGetResponse[]) {
    if (original.length === 0) {
      return original;
    }

    const result: ReplacePartsGetResponse[] = [];

    // 注文番号の降順、注文明細番号の昇順ソート
    if (showsOrderNo) {
      original.sort((a, b) => {
        if (a.orderNo !== b.orderNo) {
          return b.orderNo - a.orderNo;
        }
        return a.orderDetailNo - b.orderDetailNo;
      });

      let beforeOrederNo = 0;
      for (const row of original) {
        if (beforeOrederNo !== row.orderNo) {
          pushOrderRows(result, row);
          beforeOrederNo = row.orderNo;
        }
        result.push(row);
      }
    } else {
      // 実施日でソート
      original.sort((a, b) => {
        if (a.effectiveDate !== b.effectiveDate) {
          if (a.effectiveDate === null) {
            return 1;
          }
          if (b.effectiveDate === null) {
            return -1;
          }
          return a.effectiveDate < b.effectiveDate ? 1 : -1;
        } else {
          if (a.orderNo !== b.orderNo) {
            return b.orderNo - a.orderNo;
          }
          return a.orderDetailNo - b.orderDetailNo;
        }
      });

      for (const row of original) {
        result.push(row);
      }
    }
    return result;
  }

  function pushOrderRows(
    targetList: ReplacePartsGetResponse[],
    orderRow: ReplacePartsGetResponse
  ) {
    targetList.push({
      orderNo: orderRow.orderNo,
      orderClassValue: "",
      companyCode: orderRow.companyCode,
      companyName: ORDER_NO_TITLE,
      imo: "",
      vesselName: formatOrderNo(orderRow.orderNo),
      replyDiscountPrice: null,
      replyTaxPrice: null,
      orderDetailNo: 0,
      productGroupName: null,
      spec: null,
      productCode: "",
      replyUnitCost: null,
      shippingQuantity: null,
      replyDetailSubTotal: null,
      effectiveDate: null,
      problemProduct: null,
      problemDetail: null,
    });
    targetList.push({
      orderNo: orderRow.orderNo,
      orderClassValue: "",
      companyCode: orderRow.companyCode,
      companyName: DISCOUNT_TITLE,
      imo: "",
      vesselName: formatCurrency(orderRow.replyDiscountPrice, "▲"),
      replyDiscountPrice: null,
      replyTaxPrice: null,
      orderDetailNo: 0,
      productGroupName: null,
      spec: null,
      productCode: "",
      replyUnitCost: null,
      shippingQuantity: null,
      replyDetailSubTotal: null,
      effectiveDate: null,
      problemProduct: null,
      problemDetail: null,
    });
    targetList.push({
      orderNo: orderRow.orderNo,
      orderClassValue: "",
      companyCode: orderRow.companyCode,
      companyName: TAX_TITLE,
      imo: "",
      vesselName: formatCurrency(orderRow.replyTaxPrice, ""),
      replyDiscountPrice: null,
      replyTaxPrice: null,
      orderDetailNo: 0,
      productGroupName: null,
      spec: null,
      productCode: "",
      replyUnitCost: null,
      shippingQuantity: null,
      replyDetailSubTotal: null,
      effectiveDate: null,
      problemProduct: null,
      problemDetail: null,
    });
  }

  function formatCurrency(price: number | null, prefix: string) {
    const val = price ?? 0;
    return prefix + "￥" + val.toLocaleString();
  }

  //CSV出力ボタン押下時の処理
  function onClickCSVOutButton() {
    // queryを配列に変換する
    const queryParams = query.split("&");
    // チェックボックスの値を配列に追加する
    // 送料表示非表示
    queryParams.push(`replyDeliveryPriceOrNotShow=${showsDeliveryPrice}`);
    // 経費表示非表示
    queryParams.push(`expensesOrNotShow=${showsExpenses}`);
    // 注文番号表示非表示
    queryParams.push(`orderNoOrNotShow=${showsOrderNo}`);

    // 新たなクエリ条件を設定
    const querySub = queryParams.join("&");

    api
      .get(
        `/api/v1/companies/${
          !searchValues.companyCode ? -1 : searchValues.companyCode
        }/replace-parts-csv?${querySub}`
      )
      .then((it) => {
        //文字化け回避
        const bom = new Uint8Array([0xef, 0xbb, 0xbf]);
        const csv = new Blob([bom, it.data], {
          type: "text/csv",
        });
        //aタグ作成
        const a = document.createElement("a");

        //以下ファイル名設定のための必要事項
        if (searchValues.companyCode) {
          api
            .get<CompanyGetResponse>(
              `/api/v1/companies/${searchValues.companyCode}?searchMode=0`
            )
            .then((response) => {
              let vesselNames = "";
              //undefined対策
              if (searchValues?.imo === undefined) {
                searchValues.imo = [];
              }
              //船名を設定
              if (searchValues.analyzedVesselSelect === SELECT_VESSEL_LIST) {
                for (let i = 0; i < searchValues?.imo.length; i++) {
                  vesselNames = vesselNames + "_" + searchValues.imo[i];
                }
                //船舶無しを含む場合
                if (searchValues.vesselNoOrNotContained) {
                  vesselNames = vesselNames + "_" + NO_VESSEL;
                }
              } else {
                vesselNames = "_" + VESSEL_TOTAL;
              }

              let selectYear = "";
              //年か年度かを設定
              if (searchValues.showStartMonth === 1) {
                selectYear = START_MONTH_JAN;
              } else {
                selectYear = START_MONTH_APR;
              }

              // ダウンロードされるファイル名
              a.download =
                FILE_NAME_START +
                "_" +
                response.data.companyGetResponseSub1.companyName +
                vesselNames +
                "_" +
                searchValues.startYear +
                "～" +
                searchValues.endYear +
                selectYear +
                ".csv";

              a.href = URL.createObjectURL(csv);
              // ダウンロード開始
              a.click();
              splashMessage.show("I055");
            })
            .catch((error) => {
              if (getBizError(error)) {
                //対応するエラーメッセージを表示
                showDialog({ error });
              } else {
                showDialog({ id: "E066" });
              }
            });
        } else {
          //年か年度かを設定
          let selectYear = "";
          if (searchValues.showStartMonth === 1) {
            selectYear = START_MONTH_JAN;
          } else {
            selectYear = START_MONTH_APR;
          }

          // ダウンロードされるファイル名
          a.download =
            FILE_NAME_START +
            "_" +
            tc("全得意先") +
            "_" +
            tc("全船") +
            "_" +
            searchValues.startYear +
            "～" +
            searchValues.endYear +
            selectYear +
            ".csv";

          a.href = URL.createObjectURL(csv);
          // ダウンロード開始
          a.click();
          splashMessage.show("I055");
        }
      })
      .catch((error) => {
        if (getBizError(error)) {
          //対応するエラーメッセージを表示
          showDialog({ error });
        } else {
          showDialog({ id: "E066" });
        }
      });
  }

  // グリッドの列定義
  let columnDefs = [
    {
      headerName: tc("得意先名"),
      field: "companyName",
      width: 200,
      sortable: true,
      hide: !(auth.user().bemacFlg || auth.user().suFlg),
    },
    {
      headerName: "IMO",
      field: "imo",
      width: 150,
      sortable: true,
    },
    {
      headerName: tc("船名"),
      field: "vesselName",
      width: 150,
      cellStyle: (params: CellClassParams) => {
        if (
          [DISCOUNT_TITLE, TAX_TITLE].includes(params.data?.companyName ?? "")
        ) {
          return { textAlign: "right" };
        }
        return undefined;
      },
      sortable: true,
    },
    {
      headerName: tc("品名"),
      field: "productGroupName",
      width: 300,
      sortable: true,
    },
    {
      headerName: tc("型式"),
      field: "spec",
      width: 250,
      sortable: true,
    },
    {
      headerName: tc("コード"),
      field: "productCode",
      width: 100,
      sortable: true,
    },
    {
      headerName: tc("単価"),
      field: "replyUnitCost",
      width: 100,
      sortable: true,
      cellStyle: { textAlign: "right" },
      valueFormatter: agJapanCurrencyFormatter,
    },
    {
      headerName: tc("数量"),
      field: "shippingQuantity",
      width: 100,
      sortable: true,
      cellStyle: { textAlign: "right" },
      valueFormatter: agNumberFormatter,
    },
    {
      headerName: tc("金額"),
      field: "replyDetailSubTotal",
      width: 100,
      sortable: true,
      cellStyle: { textAlign: "right" },
      valueFormatter: agJapanCurrencyFormatter,
    },
    {
      headerName: tc("実施日"),
      field: "effectiveDate",
      width: 150,
      sortable: true,
      valueFormatter: agYmdFormatterSlash,
    },
    {
      headerName: tc("実施"),
      field: "orderClassValue",
      width: 200,
      sortable: true,
    },
    {
      headerName: tc("不具合製品"),
      field: "problemProduct",
      width: 200,
      sortable: true,
    },
    {
      headerName: tc("不具合箇所"),
      field: "problemDetail",
      width: 200,
      sortable: true,
    },
  ];

  //レンダリング
  return (
    <div className="h-100 overflow-auto">
      <div style={{ marginTop: "24px", marginBottom: "10px" }}>
        <span
          style={{
            fontWeight: "bold",
            fontSize: "20px",
          }}
        >
          {tc("部品交換実施リスト")}
        </span>
      </div>
      <div className="ms-2 mb-2">
        <span className="me-2">{tc("表示")}</span>
        {showSettings.map((it, index) => {
          return (
            <span key={it.text} className="ms-2">
              <input
                type="checkbox"
                className="form-check-input"
                id={`showSettings-${index}`}
                checked={it.checked}
                onChange={it.onChange}
                data-cy={`${it.text}チェックボックス`}
              />
              <label htmlFor={`showSettings-${index}`}>{it.text}</label>
            </span>
          );
        })}
        <span className="ms-3" style={{ color: "red" }}>
          {tc("※分析期間の設定によっては、表示されない明細があります。")}
        </span>
      </div>
      <div
        className="ag-theme-alpine b-ag-deleted-row b-header-row-white"
        style={{ position: "relative", margin: "10px 0px 0px 0px" }}
        data-cy="部品交換実施リストグリッド"
      >
        <AgGridReact
          domLayout="autoHeight"
          defaultColDef={defaultColDef}
          columnDefs={columnDefs}
          rowData={getFilteredData()}
          pagination
          paginationPageSize={20}
          ref={gridRef}
          overlayNoRowsTemplate={t(TextConst.GRID_NO_DATA)}
        />
        <PageSizeSelector grid={gridRef.current} />
      </div>
      <div className="text-center my-2">
        <input
          type="button"
          className="btn b-btn-primary"
          value={tc("CSV出力")}
          onClick={onClickCSVOutButton}
          disabled={!data?.length ? true : false}
          data-cy="部品交換実施リストCSV出力ボタン"
        />
      </div>
    </div>
  );
}

export default OrderProductList;
