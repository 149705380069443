import {
  ChangeEvent,
  forwardRef,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { Button, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import Select, { MultiValue, SingleValue } from "react-select";
import { useAuthUserContext } from "src/context/AuthUser";
import { ClassValuesGetResponse } from "src/model/api/response/ClassValuesGetResponse";
import { CompanySuggestionsGetResponse } from "src/model/api/response/CompanySuggestionsGetResponse";
import { UserSuggestionsGetResponse } from "src/model/api/response/UserSuggestionsGetResponse";
import { TextConst } from "src/util/Constant";
import {
  SelectOption,
  defaultSelectStyle,
  defaultSelectStyleMulti,
} from "src/util/SelectUtil";

import { useDialog } from "../../context/DialogProvider";
import useApi from "../../hook/useApi";
import { useSplashMessage } from "../../hook/useSplashMessage";
import { ServiceNewsPostRequest } from "../../model/api/request/ServiceNewsPostRequest";
import { ServiceNewsGetResponse } from "../../model/api/response/ServiceNewsGetResponse";
import { formatDate, getBizError } from "../../util/ecUtil";
import RequireMark from "../common/RequireMark";
import S3Uploader, { S3UploaderHandles } from "../common/S3Uploader";
import TooltipIcon from "../common/TooltipIcon";

// 公開する関数の定義
export interface ServiceNewsDetailHandles {
  show(
    serviceNewsId: number | undefined,
    screenParam: string | undefined
  ): void;
}

// 画面モード
export const MODE_MASTER_NEW = "1"; //新規
export const MODE_MASTER_UPDATE = "2"; //更新
export const MODE_BROWSE = "3"; //閲覧

// 通知先指定
const DISTRIBUTION_TARGET_ALL = "1"; // 指定なし
const DISTRIBUTION_TARGET_TRADING_ALL = "2"; // 商社
const DISTRIBUTION_TARGET_TRADING_COMPANIES = "3"; // 商社（得意先指定）
const DISTRIBUTION_TARGET_NOT_TRADING_ALL = "4"; // 商社以外
const DISTRIBUTION_TARGET_NOT_TRADING_COMPANIES = "5"; // 商社以外（得意先指定）
const DISTRIBUTION_TARGET_NOT_TRADING_USERS = "6"; // 商社以外（ユーザ指定）

type Props = {
  onClosed?: () => void;
};

/** ServiceNews詳細コンポーネント */
const ServiceNewsDetail = forwardRef<ServiceNewsDetailHandles, Props>(
  (props, ref) => {
    // API使用宣言
    const api = useApi();
    //スプラッシュメッセージ使用宣言
    const splashMessage = useSplashMessage();
    //ダイアログ使用宣言
    const showDialog = useDialog();
    // 翻訳
    const { t: tc } = useTranslation("ServiceNewsDetail");
    //ユーザID
    const userId = useAuthUserContext().user().userId;

    const uploaderRef = useRef<S3UploaderHandles>(null); // ファイル添付

    // state
    const [screenParam, setScreenParam] = useState<string | undefined>(); //画面のモード
    const [postPeriodView, setPostPeriodView] = useState(false); // 掲載期間表示
    const [titleReadOnly, setTitleReadOnly] = useState(false); // 件名編集可否
    const [subjectReadOnly, setSubjectReadOnly] = useState(false); // 内容編集可否
    const [URLReadOnly, setURLReadOnly] = useState(false); // URL編集可否
    const [fileReadOnly, setFileReadOnly] = useState(false); // ファイル編集可否
    const [show, setShow] = useState(false); // 画面表示
    const [selectedCompany, setSelectedCompany] = useState<string>();

    //新規作成・編集時の初期サービスニュースデータ
    const [serviceNews, setServiceNews] = useState<ServiceNewsPostRequest>(
      generatePostRequest()
    );
    const [mailSentFlg, setMailSentFlg] = useState(false); // 送信済みフラグ

    /** サービスニュース登録リクエスト*/
    const [postRequest, setPostRequest] = useState<ServiceNewsPostRequest>(
      generatePostRequest()
    );

    /** 通知先コンボオプション */
    const [distributionOptions, setDistributionOptions] = useState<
      SelectOption<string>[]
    >([]);
    /** 通知先得意先選択コンボオプション */
    const [companyOptions, setCompanyOptions] = useState<
      SelectOption<string>[]
    >([]);
    /** 通知先ユーザ選択コンボオプション */
    const [userOptions, setUserOptions] = useState<SelectOption<number>[]>([]);

    // 公開する関数の実装
    useImperativeHandle(ref, () => ({
      show(serviceNewsId: number | undefined, screenParam: string | undefined) {
        // 通知先コンポ
        //分類値マスタ取得APIを呼び出し
        api.get(`/api/v1/class/notification_target/values`).then((response) => {
          const target = response.data.map((it: ClassValuesGetResponse) => {
            return {
              label: it.className,
              value: it.classValue,
            };
          });
          setDistributionOptions(target);
        });
        if (serviceNewsId !== undefined) {
          //サービスニュース取得API実行
          api
            .get<ServiceNewsGetResponse>(
              `/api/v1/service-news/${serviceNewsId}`
            )
            .then((it) => {
              // 参照で開いた時は既読登録
              if (screenParam === MODE_BROWSE) {
                api
                  .post(
                    `api/v1/users/${userId}/service-news-read/${serviceNewsId}`
                  )
                  .catch((error) => {
                    showDialog({ error });
                  });
              }
              // 初期値を設定して画面表示
              const postRequest = generatePostRequest(it.data);
              saveInitialDataAndShow(postRequest, screenParam);
              // メール送信状態を保持する
              setMailSentFlg(it.data.mailSentFlg ?? false);
            })
            .catch((error) => {
              if (error.response.status === 404) {
                showDialog({ id: "E081" });
              } else {
                showDialog({ error });
              }
            });
        } else {
          //新規登録ボタン押下時は初期化して画面表示
          const postRequest = generatePostRequest();
          saveInitialDataAndShow(postRequest, screenParam);
          // メール送信状態をfalseにする
          setMailSentFlg(false);
        }
      },
    }));

    // stateに保存してモーダルを表示状態にする
    function saveInitialDataAndShow(
      postRequest: ServiceNewsPostRequest,
      screenParam: string | undefined
    ) {
      setServiceNews(postRequest);
      setPostRequest(postRequest);
      setScreenParam(screenParam);

      //画面の表示項目設定
      if (
        screenParam === MODE_MASTER_NEW ||
        screenParam === MODE_MASTER_UPDATE
      ) {
        // マスタ管理用画面項目設定
        setMasterScreen();

        // 通知先の各コンボオプションを設定
        refreshCompanyOptions(postRequest.notificationClassValue);
        if (
          postRequest.notificationClassValue ===
            DISTRIBUTION_TARGET_NOT_TRADING_USERS &&
          postRequest.serviceNewsPostRequestSub.length > 0
        ) {
          setSelectedCompany(
            postRequest.serviceNewsPostRequestSub[0].companyCode
          );
          refreshCustomerUserOptions(
            postRequest.serviceNewsPostRequestSub[0].companyCode
          );
        } else {
          setSelectedCompany(undefined);
          refreshCustomerUserOptions(undefined);
        }
      } else {
        // 検索用画面項目設定
        setBrowseScreen();
      }

      setShow(true);
    }

    // モーダル表示ハンドラ
    const handleShow = () => {
      if (serviceNews.serviceNewsId) {
        //ファイルアップロード先のpath(サービスニュースは機密性高)
        const s3root = "service-news-image/" + serviceNews.serviceNewsId + "/";
        uploaderRef.current?.init(s3root, false);
      }
    };

    // モーダルクローズハンドラ
    const handleClose = () => {
      // 新規登録、更新の場合
      if (
        screenParam === MODE_MASTER_NEW ||
        screenParam === MODE_MASTER_UPDATE
      ) {
        if (JSON.stringify(serviceNews) !== JSON.stringify(postRequest)) {
          //変更があるとき
          showDialog({
            id: "I001",
            confirm: true,
            callback: (isOk) => {
              if (isOk) {
                setShow(false);
              }
            },
          });
        } else {
          setShow(false);
        }
      }
      //閲覧の場合
      else {
        if (props.onClosed) {
          //親画面(サービスニュース一覧画面/Top画面)の再検索を実行
          props.onClosed();
        }
        setShow(false);
      }
    };

    //リクエストを作成する
    function generatePostRequest(
      serviceNews?: ServiceNewsGetResponse
    ): ServiceNewsPostRequest {
      return {
        serviceNewsId: serviceNews?.serviceNewsId ?? undefined, //サービスニュースID
        title: serviceNews?.title ?? "", //件名
        contents: serviceNews?.contents ?? "", //内容
        startDate: serviceNews?.startDate ?? undefined, //掲載開始日
        endDate: serviceNews?.endDate ?? undefined, //掲載終了日
        url: serviceNews?.url ?? "", //URL
        updateDateTime: serviceNews?.updateDateTime ?? undefined, //更新日時
        notificationClassValue:
          serviceNews?.notificationClassValue ?? DISTRIBUTION_TARGET_ALL, // 通知先指定
        serviceNewsPostRequestSub:
          serviceNews?.serviceNewsIdGetResponseSub?.map((sub) => {
            return {
              companyCode: sub.companyCode,
              userId: sub.userId,
            };
          }) ?? [], // 通知先リスト
      };
    }

    // 検索エリアテキストボックス変更
    function handleChangeInput(
      e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) {
      const name = e.target.name;
      const value = name.includes("Date")
        ? new Date(e.target.value)
        : e.target.value;
      // 入力値をセット
      setPostRequest({ ...postRequest, [name]: value });
    }

    // 通知先コンボ変更
    function handleChangeDistributionCombo(
      e: SingleValue<SelectOption<string>>
    ) {
      const notificationClassValue = e?.value ?? DISTRIBUTION_TARGET_ALL;
      setPostRequest({
        ...postRequest,
        notificationClassValue,
        serviceNewsPostRequestSub: [],
      });

      refreshCompanyOptions(notificationClassValue);
      refreshCustomerUserOptions(undefined);
    }

    // 通知先得意先コンボ変更
    function handleChangeDistributionCompanyComboMulti(
      e: MultiValue<SelectOption<string>>
    ) {
      setPostRequest({
        ...postRequest,
        serviceNewsPostRequestSub: e.map((option) => {
          return {
            companyCode: option.value,
            userId: undefined,
          };
        }),
      });
    }

    // 通知先得意先コンボ変更
    function handleChangeDistributionCompanyComboSingle(
      e: SingleValue<SelectOption<string>>
    ) {
      // 選択した得意先コードを保存
      setSelectedCompany(e?.value);
      setPostRequest({
        ...postRequest,
        serviceNewsPostRequestSub: [],
      });
      // ユーザ選択コンボ再設定
      refreshCustomerUserOptions(e?.value);
    }

    // 通知先ユーザコンボ変更
    function handleChangeDistributionUserComboMulti(
      e: MultiValue<SelectOption<number>>
    ) {
      setPostRequest({
        ...postRequest,
        serviceNewsPostRequestSub: e.map((option) => {
          return {
            companyCode: selectedCompany ?? "",
            userId: option.value,
          };
        }),
      });
    }

    // 得意先選択コンボ再設定
    function refreshCompanyOptions(notificationClassValue: string) {
      // 指定なし、商社、商社以外はコンボ非表示のためオプションをクリアする
      if (
        [
          DISTRIBUTION_TARGET_ALL,
          DISTRIBUTION_TARGET_TRADING_ALL,
          DISTRIBUTION_TARGET_NOT_TRADING_ALL,
        ].includes(notificationClassValue)
      ) {
        setCompanyOptions([]);
        return;
      }

      let onlyTrading =
        notificationClassValue === DISTRIBUTION_TARGET_TRADING_COMPANIES;
      const query = `?tradingFlg=${onlyTrading}`;
      api
        .get(`/api/v1/company-suggestions${query}`)
        .then((it) => {
          const options = it.data.map((row: CompanySuggestionsGetResponse) => {
            return {
              label: row.companyName,
              value: row.companyCode,
            };
          });
          setCompanyOptions(options);
        })
        .catch((error) => showDialog({ error }));
    }

    // ユーザ選択コンボ再設定
    function refreshCustomerUserOptions(companyCode: string | undefined) {
      if (!companyCode) {
        setUserOptions([]);
        return;
      }
      const value = companyCode ?? "";
      api
        .get(`/api/v1/user-suggestions?companyCode=${value}`)
        .then((response) => {
          const users = response.data.map((row: UserSuggestionsGetResponse) => {
            return {
              label: row.userName,
              value: row.userId,
              delFlg: row.delFlg,
            };
          });
          setUserOptions(users);
        });
    }

    //登録･更新ボタン押下時の処理
    function onClickRegistButton() {
      //掲載開始日は未入力の場合登録日を開始日とする;
      postRequest.startDate = postRequest.startDate ?? new Date();
      //  通知先が商社以外(ユーザ指定)かつユーザが指定されていない場合、エラー制御用に一時的に通知先リストを作成する
      if (
        postRequest.notificationClassValue ===
          DISTRIBUTION_TARGET_NOT_TRADING_USERS &&
        selectedCompany !== undefined &&
        postRequest.serviceNewsPostRequestSub.length === 0
      ) {
        postRequest.serviceNewsPostRequestSub = [
          {
            companyCode: selectedCompany,
            userId: undefined,
          },
        ];
      }

      //サービスニュース登録・更新API実行
      api
        .post(`/api/v1/service-news`, postRequest)
        .then((response) => {
          if (screenParam === MODE_MASTER_NEW) {
            splashMessage.show("I035");
          }
          if (screenParam === MODE_MASTER_UPDATE) {
            splashMessage.show("I051");
          }

          // 添付ファイルを処理
          //ファイルアップロード先のpath(サービスニュースは機密性高)
          const s3root =
            "service-news-image/" + response.data.serviceNewsId + "/";
          uploaderRef.current!.initAndSave(s3root, false);

          if (props.onClosed) {
            //サービスニュースリスト取得API実行フラグON
            props.onClosed();
          }
          setShow(false);
        })
        .catch((error) => {
          if (getBizError(error)) {
            //対応するエラーメッセージを表示
            showDialog({ error });
          } else {
            showDialog({ id: "E026" });
          }
          // エラー制御用の通知先リストを作成していた場合、削除する
          if (
            postRequest.notificationClassValue ===
              DISTRIBUTION_TARGET_NOT_TRADING_USERS &&
            selectedCompany !== undefined &&
            postRequest.serviceNewsPostRequestSub.length === 1 &&
            postRequest.serviceNewsPostRequestSub[0].userId === undefined
          ) {
            postRequest.serviceNewsPostRequestSub = [];
          }
        });
    }

    // マスタ管理用画面項目設定
    function setMasterScreen() {
      //掲載期間：表示
      setPostPeriodView(true);
      //件名編集：可
      setTitleReadOnly(false);
      // 内容編集：可
      setSubjectReadOnly(false);
      // URL編集：可
      setURLReadOnly(false);
      // ファイル編集：可
      setFileReadOnly(false);
    }
    // 検索用画面項目設定
    function setBrowseScreen() {
      //掲載期間：非表示
      setPostPeriodView(false);
      //件名編集：不可
      setTitleReadOnly(true);
      // 内容編集：不可
      setSubjectReadOnly(true);
      // URL編集：不可
      setURLReadOnly(true);
      // ファイル編集：不可
      setFileReadOnly(true);
    }

    // スタイル
    // ヘッダ部見出しの幅
    const itemTitleStyle = { width: "11rem" };
    const areaClass = "p-1 bg-white b-detail-items";

    // モーダルbody部レンダリング
    function modalBody() {
      return (
        <>
          <div className={areaClass}>
            {screenParam !== MODE_BROWSE && (
              <>
                <div className="row">
                  <div className="input-group">
                    <div
                      className="col-1 b-input-group-text"
                      style={itemTitleStyle}
                    >
                      {tc("通知先")}
                    </div>
                    <div
                      className={`form-control ${
                        mailSentFlg ? "disabled" : ""
                      }`}
                    >
                      <Select
                        styles={defaultSelectStyle}
                        placeholder={TextConst.COMBO_PLACEHOLDER}
                        options={distributionOptions}
                        isDisabled={mailSentFlg}
                        value={distributionOptions.find(
                          (option) =>
                            option.value === postRequest.notificationClassValue
                        )}
                        onChange={handleChangeDistributionCombo}
                      />
                      {[
                        DISTRIBUTION_TARGET_TRADING_COMPANIES,
                        DISTRIBUTION_TARGET_NOT_TRADING_COMPANIES,
                      ].includes(postRequest.notificationClassValue) && (
                        <div className="mt-2">
                          <Select
                            className="basic-multi-select"
                            styles={defaultSelectStyleMulti}
                            isMulti
                            placeholder={TextConst.COMBO_PLACEHOLDER}
                            isClearable
                            options={companyOptions}
                            isDisabled={mailSentFlg}
                            value={postRequest.serviceNewsPostRequestSub.map(
                              (sub) => {
                                return {
                                  value: sub.companyCode,
                                  label: companyOptions.find(
                                    (company) =>
                                      company.value == sub.companyCode
                                  )?.label,
                                };
                              }
                            )}
                            onChange={handleChangeDistributionCompanyComboMulti}
                          />
                        </div>
                      )}
                      {[DISTRIBUTION_TARGET_NOT_TRADING_USERS].includes(
                        postRequest.notificationClassValue
                      ) && (
                        <div className="mt-2 d-flex">
                          <div className="w-50">
                            <Select
                              styles={defaultSelectStyle}
                              placeholder={TextConst.COMBO_PLACEHOLDER}
                              isClearable
                              options={companyOptions}
                              isDisabled={mailSentFlg}
                              value={{
                                value: selectedCompany,
                                label: companyOptions.find(
                                  (company) => company.value == selectedCompany
                                )?.label,
                              }}
                              onChange={
                                handleChangeDistributionCompanyComboSingle
                              }
                            />
                          </div>
                          <div className="w-50 ps-2">
                            <Select
                              styles={defaultSelectStyleMulti}
                              placeholder={TextConst.COMBO_PLACEHOLDER}
                              isClearable
                              isMulti
                              options={userOptions}
                              isDisabled={mailSentFlg}
                              value={postRequest.serviceNewsPostRequestSub.map(
                                (sub) => {
                                  return {
                                    value: sub.userId,
                                    label: userOptions.find(
                                      (user) => user.value == sub.userId
                                    )?.label,
                                  };
                                }
                              )}
                              onChange={handleChangeDistributionUserComboMulti}
                            />
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </>
            )}
            <div className="row">
              <div className="input-group">
                <div
                  className="col-1 b-input-group-text"
                  style={itemTitleStyle}
                >
                  {tc("件名")}
                  {screenParam !== MODE_BROWSE && <RequireMark></RequireMark>}
                </div>
                {!titleReadOnly && (
                  <input
                    type="text"
                    name="title"
                    maxLength={64}
                    className="form-control"
                    style={{ width: 330 }}
                    value={postRequest.title}
                    onChange={handleChangeInput}
                    data-cy="件名テキスト"
                  />
                )}
                {titleReadOnly && (
                  <span className="form-control">{postRequest.title}</span>
                )}
              </div>
            </div>
            <div className="row">
              <div className="input-group">
                <div
                  className="col-1 b-input-group-text"
                  style={{ width: "11rem" }}
                >
                  {tc("内容")}
                  {screenParam !== MODE_BROWSE && <RequireMark></RequireMark>}
                </div>
                {!subjectReadOnly && (
                  <textarea
                    className="form-control textarea"
                    name="contents"
                    maxLength={500}
                    style={{ width: 330 }}
                    value={postRequest.contents}
                    onChange={handleChangeInput}
                    data-cy="内容テキスト"
                  />
                )}
                {subjectReadOnly && (
                  <span
                    className="form-control"
                    style={{ whiteSpace: "pre-wrap" }}
                  >
                    {postRequest.contents}
                  </span>
                )}
              </div>
            </div>
            <div className="row">
              <div className="input-group">
                <div className=" b-input-group-text" style={itemTitleStyle}>
                  URL
                </div>
                {!URLReadOnly && (
                  <input
                    type="text"
                    name="url"
                    maxLength={100}
                    className="form-control"
                    style={{ width: 330 }}
                    value={postRequest.url}
                    onChange={handleChangeInput}
                    data-cy="URLテキスト"
                  />
                )}
                {URLReadOnly && (
                  <span className="form-control">
                    <a
                      className="text-link"
                      href="#"
                      onClick={() => window.open(postRequest.url, "_blank")}
                    >
                      {postRequest.url}
                    </a>
                  </span>
                )}
              </div>
            </div>
            {postPeriodView && (
              <div className="row">
                <div className="input-group">
                  <div className="b-input-group-text" style={itemTitleStyle}>
                    {tc("掲載開始日")}
                  </div>
                  <input
                    type="date"
                    name="startDate"
                    className="form-control"
                    value={formatDate(postRequest.startDate, "YYYY-MM-DD")}
                    onChange={handleChangeInput}
                    disabled={screenParam === MODE_MASTER_UPDATE}
                    data-cy="掲載開始日テキスト"
                  />
                  <span className="b-input-group-text" style={itemTitleStyle}>
                    {tc("掲載終了日")}
                  </span>
                  <input
                    type="date"
                    name="endDate"
                    className="form-control"
                    value={formatDate(postRequest.endDate, "YYYY-MM-DD")}
                    onChange={handleChangeInput}
                    data-cy="掲載終了日テキスト"
                  />
                </div>
              </div>
            )}
          </div>
          {/* BEMAC管理者でマスタ管理、新規または更新の場合 */}
          <div className={areaClass}>
            {!fileReadOnly && (
              <div className="pt-2">
                <div className="pb-1">
                  <span
                    className="b-input-group-text"
                    style={{ display: "inline" }}
                  >
                    {tc("ファイルを選択")}
                  </span>
                  <span className="ms-2" style={{ color: "red" }}>
                    {tc("※ファイル添付は、最大5件までです。")}
                  </span>
                </div>
              </div>
            )}
            <S3Uploader
              editable={!fileReadOnly}
              maxCount={5}
              ref={uploaderRef}
              imgWidth={200}
            />
          </div>
        </>
      );
    }

    // レンダリング
    return (
      <Modal
        size="xl"
        show={show}
        onShow={handleShow}
        onHide={handleClose}
        scrollable
        data-cy="サービスニュース詳細モーダル"
      >
        <Modal.Header closeButton>
          <Modal.Title>{tc("ServiceNews詳細")}</Modal.Title>
        </Modal.Header>

        <Modal.Body>{modalBody()}</Modal.Body>

        <Modal.Footer>
          {/* マスタ管理の場合 */}
          {screenParam !== MODE_BROWSE && (
            <>
              <Button
                className="b-btn-primary m-0"
                onClick={onClickRegistButton}
                data-cy="更新ボタン"
              >
                {tc(screenParam === MODE_MASTER_UPDATE ? "更新" : "登録")}
              </Button>
              <TooltipIcon messageId="T005" position="up-left"></TooltipIcon>
            </>
          )}

          <Button
            className="b-btn-close"
            onClick={handleClose}
            data-cy="Closeボタン"
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
);

export default ServiceNewsDetail;
