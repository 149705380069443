import dayjs from "dayjs";
import { CSSProperties, SetStateAction, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { SingleValue } from "react-select";
import AsyncSelect from "react-select/async";
import { useAuthUserContext } from "src/context/AuthUser";
import { useDialog } from "src/context/DialogProvider";
import useApi from "src/hook/useApi";
import { useDispatchReport } from "src/hook/useDispatchReport";
import { FileUploadHistoriesPostRequest } from "src/model/api/request/FileUploadHistoriesPostRequest";
import { CompanySuggestionsGetResponse } from "src/model/api/response/CompanySuggestionsGetResponse";
import { FileUploadHistoryGetResponse } from "src/model/api/response/FileUploadHistoryGetResponse";
import { VesselGetResponse } from "src/model/api/response/VesselGetResponse";
import { VesselSuggestionsGetResponse } from "src/model/api/response/VesselSuggestionsGetResponse";
import { VesselTroublesGetResponse } from "src/model/api/response/VesselTroublesGetResponse";
import { VesselVisitsGetResponse } from "src/model/api/response/VesselVisitsGetResponse";
import { FileUploadFileType, TextConst } from "src/util/Constant";
import { SelectOption, defaultSelectStyle } from "src/util/SelectUtil";

import { useSplashMessage } from "../../hook/useSplashMessage";
import { getBizError } from "../../util/ecUtil";
import TooltipIcon from "../common/TooltipIcon";
import MaintenanceHistoryListDataArea from "./MaintenanceHistoryListDataArea";
import MaintenanceHistoryListYearTabs from "./MaintenanceHistoryListYearTabs";

function MaintenanceHistoryList() {
  //クエリパラメータの取り出し
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const queryImoNo = query.get("imono") ?? ""; // IMO番号

  // ダイアログ使用宣言
  const showDialog = useDialog();
  // API使用宣言
  const api = useApi();
  //スプラッシュメッセージ使用宣言
  const splashMessage = useSplashMessage();
  // メッセージ
  const { t } = useTranslation();
  // 翻訳
  const { t: tc } = useTranslation("MaintenanceHistoryList");

  // 工事レポート
  const dispatchReport = useDispatchReport();
  // ユーザ情報
  const auth = useAuthUserContext();

  const [selectedYear, setSelectedYear] = useState<number>();

  // 検索条件（コンボはundefinedが入る）
  type SearchValueType = {
    companyCode: string | undefined;
    companyName: string | undefined; // 検索には使用しない。画面遷移による初期値設定に使用。
    imoNo: string | undefined;
    vesselName: string | undefined; // 検索には使用しない。画面遷移による初期値設定に使用。
  };
  const [searchValues, setSearchValues] = useState<SearchValueType>({
    companyCode: !auth.user().bemacFlg ? auth.user().companyCode : undefined,
    companyName: !auth.user().bemacFlg ? auth.user().companyName : undefined,
    imoNo: queryImoNo,
    vesselName: undefined,
  });

  const [vessel, setVessel] = useState<VesselGetResponse>();

  /** 訪船履歴表示データ */
  const [visitDatas, setVisitDatas] = useState<VesselVisitsGetResponse[]>([]);

  /** 過去問い合わせ内容表示データ */
  const [troubleDatas, setTroubleDatas] = useState<VesselTroublesGetResponse[]>(
    []
  );

  /** 不具合対応履歴前回取込ファイル名 */
  const [lastTroublesImportFileName, setLastTroublesImportFileName] =
    useState<string>("");
  /** 不具合対応履歴前回取込日時 */
  const [lastTroublesImportDateTime, setLastTroublesImportDateTime] =
    useState<string>("");
  /** 訪船履歴前回取込ファイル名 */
  const [lastVisitsImportFileName, setLastVisitsImportFileName] =
    useState<string>("");
  /** 訪船履歴前回取込日時 */
  const [lastVisitsImportDateTime, setLastVisitsImportDateTime] =
    useState<string>("");
  /** 工事レポート前回取込日時 */
  const [lastReportImportDateTime, setLastReportImportDateTime] =
    useState<string>("");

  // 得意先コンボ（得意名キー入力ごとにAPI通信）
  const companyOptions = (inputValue: string) => {
    const companyName = encodeURIComponent(inputValue);
    return api
      .get(
        `/api/v1/company-suggestions?companyName=${companyName}&searchDelCompanyFlg=true`
      )
      .then((it) => {
        return it.data.map((row: CompanySuggestionsGetResponse) => {
          return {
            label: row.companyName,
            value: row.companyCode,
          };
        });
      });
  };
  // 船名コンボ（船名キー入力ごとにAPI通信）
  const vesselLoadOptions = (inputValue: string) => {
    const companyCode = searchValues.companyCode ?? "";
    const vesselName = encodeURIComponent(inputValue);
    return api
      .get(
        `/api/v1/vessel-suggestions?companyCode=${companyCode}&vesselName=${vesselName}`
      )
      .then((it) => {
        return it.data.map((row: VesselSuggestionsGetResponse) => {
          return {
            label: row.shipName,
            value: row.imoNo,
          };
        });
      });
  };

  useEffect(() => {
    // IMOがクエリパラメータにあれば船名コンボの初期値を設定
    if (queryImoNo) {
      api
        .get<VesselGetResponse>(`/api/v1/vessels/${queryImoNo}`)
        .then((response) => {
          setVessel(response.data);
          setSearchValues({
            ...searchValues,
            imoNo: response.data.imoNo,
            vesselName: response.data.vesselName,
          });
        })
        .catch((error) => {
          showDialog({ error });
        });

      // 検索実施
      search(queryImoNo);
    }
    // 管理者の場合、前回の取込情報を取得
    if (auth.isBemacAdmin()) {
      // 訪船履歴
      setImportDatas(
        FileUploadFileType.VISIT_VESSEL_HISTORY_CSV,
        setLastVisitsImportDateTime,
        setLastVisitsImportFileName
      );
      // 不具合対応履歴
      setImportDatas(
        FileUploadFileType.TROUBLE_HISTORY_CSV,
        setLastTroublesImportDateTime,
        setLastTroublesImportFileName
      );
      // 工事レポート
      setImportDatas(
        FileUploadFileType.DISPATCH_REPORT_DIR,
        setLastReportImportDateTime
      );
    }
  }, []);

  /**
   * 前回の取込情報取得
   * @param fileType ファイル種別
   * @param setDateTime 日時をstateに保存する関数
   * @param setFileName ファイル名をstateに保存する関数
   */
  function setImportDatas(
    fileType: string,
    setDateTime: (value: SetStateAction<string>) => void,
    setFileName?: (value: SetStateAction<string>) => void
  ) {
    api
      .get<FileUploadHistoryGetResponse>(
        `/api/v1/file-upload-histories/${fileType}`
      )
      .then((response) => {
        const uploadDateTime = response.data.uploadDateTime;
        const fileName = response.data.fileName ?? "";
        setDateTime(uploadDateTime);
        if (setFileName) {
          setFileName(fileName);
        }
      })
      .catch((error) => {
        setDateTime("");
        if (setFileName) {
          setFileName("");
        }
        if (error.response.status === 404) {
          // not foundは何もしない
        } else {
          showDialog({ error });
        }
      });
  }

  // 検索エリアコンボ変更
  function handleChangeSelect(
    name: string,
    e: SingleValue<SelectOption<string | number>>
  ) {
    // 選択値をセット
    const value = e?.value;
    const label = e?.label;
    setSearchValues((prev) => {
      return { ...prev, [name]: value };
    });
    // 会社を選択
    if (name === "companyCode") {
      // 会社名を保持
      setSearchValues((prev) => {
        return { ...prev, companyName: label };
      });
      // 船の情報はリセット
      searchVesselInfo(undefined);
    }
    // 船名を選択
    if (name === "imoNo") {
      const imoNo = value?.toString();
      // 選択した船舶の情報を検索
      searchVesselInfo(imoNo);

      if (imoNo) {
        // 船名を指定していたら訪船履歴、不具合対応履歴を検索
        search(imoNo);
      } else {
        // 船名をクリアしたら初期化
        setVisitDatas([]);
        setTroubleDatas([]);
      }
    }
  }

  function searchVesselInfo(imoNo?: string) {
    if (imoNo) {
      // 船舶情報検索
      api
        .get(`/api/v1/vessels/${imoNo}`)
        .then((response) => {
          setVessel(response.data);
          setSearchValues((prev) => ({
            ...prev,
            vesselName: response.data.vesselName,
          }));
        })
        .catch((error) => {
          setSearchValues((prev) => ({ ...prev, imoNo, vesselName: "" }));
        });
    } else {
      setVessel(undefined);
      setSearchValues((prev) => ({ ...prev, imoNo, vesselName: "" }));
    }
  }

  function search(imoNo: string) {
    // 訪船履歴取得
    const promiseVisits = api.get(`/api/v1/vessel-visits/${imoNo}`);

    // 不具合履歴取得
    const promiseTroubles = api.get(`/api/v1/vessel-troubles/${imoNo}`);

    Promise.all([promiseVisits, promiseTroubles])
      .then((response) => {
        setVisitDatas(
          response[0].data.map((row: VesselVisitsGetResponse) => {
            return row;
          })
        );

        setTroubleDatas(
          response[1].data.map((row: VesselTroublesGetResponse) => {
            return row;
          })
        );
      })
      .catch((error) => {
        if (error.response.status === 404) {
          showDialog({ id: "E073" });
        } else {
          if (getBizError(error)) {
            //対応するエラーメッセージを表示
            showDialog({ error });
          } else {
            showDialog({ id: "E026" });
          }
        }
        setVisitDatas([]);
        setTroubleDatas([]);
      });
  }

  /** 検索結果エリアを表示するかどうか */
  function visibleResultArea() {
    // 訪船履歴か不具合対応履歴いずれかが1件でもあれば表示する
    return visitDatas.length > 0 || troubleDatas.length > 0;
  }

  /** 年の切り換え */
  function onScrolledYear(year: number) {
    setSelectedYear(year);
  }

  /** 工事ファイルアップロードボタンクリック */
  function handleClickReportUpload() {
    // 確認ダイアログを表示
    showDialog({
      id: "I080",
      confirm: true,
      callback: (isOk) => {
        if (isOk) {
          // アップロード処理
          uploadReportFiles();
        }
      },
    });
  }

  function uploadReportFiles() {
    // アップロード処理
    dispatchReport
      .upload()
      .then((result) => {
        // アップロード完了時（キャンセル時はresult=false）
        if (result) {
          // アップロード日時を登録
          const request: FileUploadHistoriesPostRequest = {
            fileType: FileUploadFileType.DISPATCH_REPORT_DIR,
            fileName: "",
            uploadDateTime: "",
          };
          api
            .post(`/api/v1/file-upload-histories`, request)
            .then((response) => {
              // 登録されたアップロード日時を保存
              setLastReportImportDateTime(response.data.uploadDateTime);

              // 工事レポート
              setImportDatas(
                FileUploadFileType.DISPATCH_REPORT_DIR,
                setLastReportImportDateTime
              );
            });
          // 正常終了メッセージ表示
          showDialog({ id: "I081" });
          if (searchValues.imoNo) {
            // 船名を指定していたら訪船履歴、不具合対応履歴を検索
            search(searchValues.imoNo);
          }
        }
      })
      .catch((error) => {
        // エラーメッセージ
        showDialog({ text: error.message });
      });
  }

  /** 訪船履歴取込ボタンクリック */
  function handleClickVisitsImportButton(
    e: React.ChangeEvent<HTMLInputElement>
  ) {
    let formData = new FormData();
    formData.append("vesselVisitsCsv", e.currentTarget.files![0]);
    api
      .post(`/api/v1/vessel-visits-csv`, formData)
      .then((it) => {
        splashMessage.show("I044");
        // 訪船履歴
        setImportDatas(
          FileUploadFileType.VISIT_VESSEL_HISTORY_CSV,
          setLastVisitsImportDateTime,
          setLastVisitsImportFileName
        );

        if (searchValues.imoNo) {
          // 船名を指定していたら訪船履歴、不具合対応履歴を検索
          search(searchValues.imoNo);
        }
      })
      .catch((error) => {
        //csvエラーファイルが返ってきているかどうか
        if (error.response.data !== "") {
          //文字化け回避
          const bom = new Uint8Array([0xef, 0xbb, 0xbf]);
          const csv = new Blob([bom, error.response.data], {
            type: "text/csv",
          });
          //aタグ作成
          const a = document.createElement("a");
          // ダウンロードされるファイル名
          a.download = "エラーファイル_訪船履歴取込.csv";
          a.href = URL.createObjectURL(csv);
          // ダウンロード開始
          a.click();
        } else {
          if (getBizError(error)) {
            //対応するエラーメッセージを表示
            showDialog({ error });
          } else {
            showDialog({ id: "E010" });
          }
        }
      });
  }

  /** 不具合対応履歴取込ボタンクリック */
  function handleClickTroublesImportButton(
    e: React.ChangeEvent<HTMLInputElement>
  ) {
    let formData = new FormData();
    formData.append("vesselTroublesCsv", e.currentTarget.files![0]);
    api
      .post(`/api/v1/vessel-troubles-csv`, formData)
      .then((it) => {
        splashMessage.show("I044");
        if (searchValues.imoNo) {
          // 船名を指定していたら訪船履歴、不具合対応履歴を検索
          search(searchValues.imoNo);
        }
        // 不具合対応履歴のログを取得
        setImportDatas(
          FileUploadFileType.TROUBLE_HISTORY_CSV,
          setLastTroublesImportDateTime,
          setLastTroublesImportFileName
        );
      })
      .catch((error) => {
        //csvエラーファイルが返ってきているかどうか
        if (error.response.data !== "") {
          //文字化け回避
          const bom = new Uint8Array([0xef, 0xbb, 0xbf]);
          const csv = new Blob([bom, error.response.data], {
            type: "text/csv",
          });
          //aタグ作成
          const a = document.createElement("a");
          // ダウンロードされるファイル名
          a.download = "エラーファイル_不具合対応履歴.csv";
          a.href = URL.createObjectURL(csv);
          // ダウンロード開始
          a.click();
        } else {
          if (getBizError(error)) {
            //対応するエラーメッセージを表示
            showDialog({ error });
          } else {
            showDialog({ id: "E010" });
          }
        }
      });
  }

  /** アップロード情報ツールチップレンダー */
  function renderTooltip() {
    const itemStyle: CSSProperties = {
      marginLeft: "10px",
    };

    const visitDate = lastVisitsImportDateTime
      ? dayjs(lastVisitsImportDateTime).format("YYYY/MM/DD HH:mm:ss")
      : "";
    const troubleDate = lastTroublesImportDateTime
      ? dayjs(lastTroublesImportDateTime).format("YYYY/MM/DD HH:mm:ss")
      : "";
    const reportDate = lastReportImportDateTime
      ? dayjs(lastReportImportDateTime).format("YYYY/MM/DD HH:mm:ss")
      : "";

    return (
      <div className="text-start">
        <p>
          <label>{tc("【不具合対応履歴】")}</label>
          <br />
          <label style={itemStyle}>
            {tc("前回取込ファイル名")}：{lastTroublesImportFileName}
          </label>
          <br />
          <label style={itemStyle}>
            {tc("前回取込日時")}：{troubleDate}
          </label>
        </p>
        <p>
          <label>{tc("【訪船履歴】")}</label>
          <br />
          <label style={itemStyle}>
            {tc("前回取込ファイル名")}：{lastVisitsImportFileName}
          </label>
          <br />
          <label style={itemStyle}>
            {tc("前回取込日時")}：{visitDate}
          </label>
        </p>
        <p>
          <label>{tc("【工事レポート】")}</label>
          <br />
          <label style={itemStyle}>
            {tc("前回取込日時")}：{reportDate}
          </label>
        </p>
      </div>
    );
  }

  /** メインレンダー */
  return (
    <>
      <div className="title-area-style">
        <span>{tc("メンテナンス履歴一覧")}</span>
      </div>
      <div style={{ width: "95%", margin: "0px auto" }}>
        <div className="my-3">
          <div className="row">
            <div className="col-4">
              <div className="input-group" data-cy="得意先コンボ">
                <span className="b-input-group-text" style={{ width: "120px" }}>
                  {tc("得意先名")}
                </span>
                {(auth.user().bemacFlg || auth.user().suFlg) && (
                  <span className="form-control">
                    <AsyncSelect
                      value={
                        !searchValues.companyCode
                          ? null
                          : {
                              value: searchValues.companyCode,
                              label: searchValues.companyName,
                            }
                      }
                      styles={defaultSelectStyle}
                      placeholder={TextConst.COMBO_PLACEHOLDER}
                      isClearable
                      cacheOptions
                      defaultOptions
                      loadOptions={companyOptions}
                      onChange={(e) => {
                        handleChangeSelect("companyCode", e);
                      }}
                    />
                  </span>
                )}
                {!auth.user().bemacFlg && !auth.user().suFlg && (
                  <span className="form-control" data-cy="得意先名">
                    {searchValues.companyName}
                  </span>
                )}
              </div>
            </div>
            <div className="col-4">
              <div className="input-group" data-cy="船名コンボ">
                <span className="b-input-group-text" style={{ width: "120px" }}>
                  {tc("船名")}
                </span>
                <span className="form-control">
                  <AsyncSelect
                    value={
                      !searchValues.imoNo
                        ? null
                        : {
                            value: searchValues.imoNo,
                            label: searchValues.vesselName,
                          }
                    }
                    styles={defaultSelectStyle}
                    placeholder={TextConst.COMBO_PLACEHOLDER}
                    isClearable
                    cacheOptions
                    defaultOptions
                    key={searchValues.companyCode}
                    loadOptions={vesselLoadOptions}
                    onChange={(e) => {
                      handleChangeSelect("imoNo", e);
                    }}
                  />
                </span>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-4">
              <div className="input-group">
                <span className="b-input-group-text" style={{ width: "120px" }}>
                  {tc("船番")}
                </span>
                <span className="form-control" data-cy="船番">
                  {vessel?.yardNo}
                </span>
              </div>
            </div>
            <div className="col-4">
              <div className="input-group">
                <span className="b-input-group-text" style={{ width: "120px" }}>
                  {tc("造船所")}
                </span>
                <span className="form-control" data-cy="造船所">
                  {vessel?.vesselBuilder}
                </span>
              </div>
            </div>
            <div className="col-4">
              <div className="input-group">
                <span className="b-input-group-text" style={{ width: "120px" }}>
                  {tc("竣工年")}
                </span>
                <span className="form-control" data-cy="竣工年">
                  {vessel?.year}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      {visibleResultArea() ? (
        <div
          className="mx-auto my-3 p-3 bg-white"
          style={{ width: "95%", borderRadius: "15px" }}
        >
          <MaintenanceHistoryListYearTabs
            visits={visitDatas}
            troubles={troubleDatas}
            selectedYear={selectedYear}
            onScrolledYear={onScrolledYear}
          />
          <MaintenanceHistoryListDataArea
            imoNo={searchValues.imoNo}
            visits={visitDatas}
            troubles={troubleDatas}
            year={selectedYear}
          />
        </div>
      ) : !searchValues.imoNo ? (
        <div
          className="my-3 p-3"
          style={{ height: "200px", textAlign: "center" }}
        >
          {t("message.E067")}
        </div>
      ) : (
        <div
          className="my-3 p-3"
          style={{ height: "200px", textAlign: "center" }}
        >
          {t(TextConst.GRID_NO_DATA)}
        </div>
      )}
      {auth.isBemacAdmin() && (
        <div className="my-2">
          <div className="text-center my-2">
            <input
              type="file"
              id="visit"
              accept=".csv"
              style={{ display: "none" }}
              onChange={handleClickVisitsImportButton}
              onClick={(e: any) => {
                e.target.value = "";
              }}
              data-cy="訪船履歴ファイル選択"
            />
            <input
              type="button"
              className="btn b-btn-primary"
              value={tc("訪船履歴取込")}
              onClick={() => document.getElementById("visit")?.click()}
              data-cy="訪船履歴取込ボタン"
            />
            <input
              type="file"
              id="trouble"
              accept=".csv"
              style={{ display: "none" }}
              onChange={handleClickTroublesImportButton}
              onClick={(e: any) => {
                e.target.value = "";
              }}
              data-cy="不具合対応履歴ファイル選択"
            />
            <input
              type="button"
              className="btn b-btn-primary ms-3"
              value={tc("不具合対応履歴取込")}
              onClick={() => document.getElementById("trouble")?.click()}
              data-cy="不具合対応履歴取込ボタン"
            />
            <input
              type="button"
              className="btn b-btn-primary ms-3"
              value={tc("工事レポートアップロード")}
              onClick={handleClickReportUpload}
              data-cy="工事レポートアップロードボタン"
            />
            <TooltipIcon position="up-left">{renderTooltip()}</TooltipIcon>
          </div>
        </div>
      )}
    </>
  );
}

export default MaintenanceHistoryList;
