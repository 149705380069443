/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import React, { useRef } from "react";
import { Nav, NavDropdown } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { NavLink, useNavigate } from "react-router-dom";
import { replaceMessageArgs } from "src/util/ecUtil";

import {
  AuthUserContextType,
  useAuthUserContext,
} from "../../context/AuthUser";
import UserDetail, {
  UserDetailHandles,
  statusUpdate,
} from "../setting/UserDetail";

function TopLogo() {
  const navigate = useNavigate();
  const authUser: AuthUserContextType = useAuthUserContext();

  // ユーザ情報
  const auth = useAuthUserContext();
  const userDetailRef = useRef<UserDetailHandles>(null); //ユーザ詳細

  // 翻訳
  const { t: tc } = useTranslation("TopLogo");

  //コンポーネント全体のstyle
  const topLogoStyle: React.CSSProperties = {
    backgroundColor: "#F5F5F5",
    width: "280px",
    position: "absolute",
    height: "100%",
  };

  //ユーザ情報のcss
  const userInfoCss = css`
    opacity: 1;
    .nav-link:hover {
      opacity: 0.6;
    }
  `;

  //ログアウトする
  function handleClickLogout() {
    authUser.signout(() => {
      navigate("/");
    });
  }

  return (
    <div style={topLogoStyle}>
      {/*Massaロゴ */}
      <img
        src={`${process.env.PUBLIC_URL}/top-background.png`}
        alt="MaSSA-Logo"
        style={{
          position: "absolute",
          top: "0px",
          left: "0px",
          width: "280px",
          height: "600px",
        }}
      />
      <Nav.Item
        style={{
          position: "relative",
          top: "30px",
          margin: "0px",
          left: "30px",
        }}
      >
        <Nav.Link as={NavLink} to="/">
          <img
            src={`${process.env.PUBLIC_URL}/jump-to-top-icon.png`}
            alt="MaSSA-Logo"
            style={{
              width: "132px",
              height: "105px",
            }}
            data-cy="MaSSAロゴアイコン"
          />
        </Nav.Link>

        {/*ユーザー情報 */}
        <NavDropdown
          css={userInfoCss}
          style={{
            position: "relative",
            top: "50px",
          }}
          title={
            <>
              <img
                src={`${process.env.PUBLIC_URL}/user-icon.png`}
                alt="userIcon"
                style={{
                  width: "36px",
                  height: "36px",
                }}
                data-cy="ユーザアイコン"
              />
              <label
                className="hover-pointer px-2"
                style={{
                  position: "absolute",
                  color: "black",
                  width: "200px",
                  whiteSpace: "pre-wrap",
                }}
              >
                {replaceMessageArgs(tc("氏名Format", "{0}様"), [
                  auth.user().userName,
                ])}
              </label>
            </>
          }
          align="start"
        >
          <NavDropdown.Item
            onClick={() =>
              userDetailRef.current?.show(
                auth.user().userId,
                statusUpdate,
                auth.user().bemacFlg
              )
            }
            data-cy="TOPユーザ情報リンク"
          >
            ユーザ情報
          </NavDropdown.Item>
          <NavDropdown.Item
            onClick={handleClickLogout}
            data-cy="TOPログアウトリンク"
          >
            ログアウト
          </NavDropdown.Item>
        </NavDropdown>
      </Nav.Item>

      <UserDetail ref={userDetailRef} />
    </div>
  );
}

export default TopLogo;
