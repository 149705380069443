import { ColDef } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import { forwardRef, useImperativeHandle, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useSplashMessage } from "src/hook/useSplashMessage";
import { agJapanCurrencyFormatter } from "src/util/AgGridUtil";

import { useDialog } from "../../context/DialogProvider";
import useApi from "../../hook/useApi";
import { CompanyGetResponse } from "../../model/api/response/CompanyGetResponse";
import { VesselGetResponse } from "../../model/api/response/VesselGetResponse";
import { VesselProductsGetResponse } from "../../model/api/response/VesselProductsGetResponse";
import { getBizError } from "../../util/ecUtil";
import {
  MIDDLE_CLASS_VALUE,
  PRODUCT_GOODS_CODE,
  PRODUCT_GROUP_NAME,
  SPEC,
  TIMING_SUBS,
} from "../../util/lcm/LcmConst";

// 公開する関数の定義
export interface PartsReplaceListHandles {
  show(
    company: CompanyGetResponse | undefined,
    vessel: VesselGetResponse | undefined,
    timing: number,
    isDone: boolean,
    result: VesselProductsGetResponse[],
    yearCheckValue: number
  ): void;
}

type PartsReplaceListType = {
  largeClassName: String | undefined;
  middleClassName: String | undefined;
  productCode: String | undefined;
  productGroupName: String | undefined;
  spec: String | undefined;
  unitPrice: number | undefined;
  quantity: number | undefined;
  subtotal: number | undefined;
};

/** コンポーネント */
const PartsReplaceList = forwardRef<PartsReplaceListHandles>((props, ref) => {
  // 翻訳
  const { t: tc } = useTranslation("PartsReplaceList");

  //定数定義
  const FILE_NAME = tc("部品交換内容一覧FileName", "部品交換内容一覧");

  // state宣言
  const [vessel, setVessel] = useState<VesselGetResponse>();
  const [company, setCompany] = useState<CompanyGetResponse>();
  const [show, setShow] = useState(false);
  const [timing, setTiming] = useState("");
  const [isDone, setIsDone] = useState<boolean>();

  // 部品交換内容グリッドレコード
  const [gridPartsReplaceList, setGridPartsReplaceList] = useState<
    PartsReplaceListType[] | null
  >([]);

  // API使用宣言
  const api = useApi();

  //スプラッシュメッセージ使用宣言
  const splashMessage = useSplashMessage();

  //ダイアログ仕様宣言
  const showDialog = useDialog();

  // 表示年度切替
  const [yearCheckValue, setYearCheckValue] = useState<number>();

  // 公開する関数の実装
  useImperativeHandle(ref, () => ({
    show(
      company: CompanyGetResponse | undefined,
      vessel: VesselGetResponse | undefined,
      timing: number,
      isDone: boolean,
      vesselProduct: VesselProductsGetResponse[],
      yearCheckValue: number
    ) {
      const gridDataList: PartsReplaceListType[] = [];
      setCompany(company);
      setVessel(vessel);
      setShow(true);
      setTiming(timing.toString());
      setIsDone(isDone);
      setYearCheckValue(yearCheckValue);

      vesselProduct.forEach((row) => {
        // 該当年度の金額情報を取得
        const productSub = row.vesselProductsGetResponseSub.find(
          (p) =>
            (yearCheckValue === 0 &&
              TIMING_SUBS.includes(Number(timing)) &&
              !isDone &&
              p.inspectionYearFlg &&
              (Number(p.inspectionYearClassValue) === timing ||
                Number(p.inspectionYearClassValue) === timing + 1)) ||
            ((yearCheckValue !== 0 ||
              !TIMING_SUBS.includes(Number(timing)) ||
              isDone ||
              !p.inspectionYearFlg) &&
              Number(p.inspectionYearClassValue) === timing &&
              ((isDone && p.orderReplyDetailSubTotal) ||
                (!isDone && p.inspectionYearFlg)))
        );
        if (productSub) {
          gridDataList.push({
            largeClassName: row.largeClassName,
            middleClassName: row.middleClassName,
            productCode: row.productCode,
            productGroupName: row.productGroupName,
            spec: row.spec,
            unitPrice: isDone
              ? productSub?.orderReplyUnitCost
              : productSub?.recommendUnitCost,
            quantity: isDone
              ? productSub?.orderReplyQuantity
              : productSub?.recommendQuantity,
            subtotal: isDone
              ? productSub?.orderReplyDetailSubTotal
              : productSub?.recommendDetailSubTotal,
          });
        }
      });

      setGridPartsReplaceList(gridDataList);
    },
  }));

  // グリッドの行の高さ
  const rowHeight = 20;

  // モーダルクローズハンドラ
  const handleClose = () => {
    setShow(false);
  };

  // ヘッダ部見出しの幅
  const itemTitleStyle = { width: "8rem" };

  // 部品交換内容グリッドの列定義
  const columnDefs: ColDef[] = [
    {
      headerName: tc("大分類"),
      field: "largeClassName",
      width: 100,
      resizable: true,
      lockPosition: "left",
      headerClass: [
        "b-cell-border-right-solid-thin-gray",
        "b-header-cell-center",
      ],
      colId: "largeClassValue",
      cellClass: ["b-cell-border-right-solid-thin-gray", "text-start"],
    },
    {
      headerName: tc("中分類"),
      field: MIDDLE_CLASS_VALUE,
      width: 110,
      resizable: true,
      lockPosition: "left",
      headerClass: [
        "b-cell-border-right-solid-thin-gray",
        "b-header-cell-center",
      ],
      colId: MIDDLE_CLASS_VALUE,
      cellClass: ["b-cell-border-right-solid-thin-gray", "text-start"],
    },
    {
      headerName: "Code",
      field: PRODUCT_GOODS_CODE,
      width: 110,
      resizable: true,
      lockPosition: "left",
      headerClass: [
        "b-cell-border-right-solid-thin-gray",
        "b-header-cell-center",
      ],
      colId: PRODUCT_GOODS_CODE,
      cellClass: ["b-cell-border-right-solid-thin-gray", "text-end"],
    },
    {
      headerName: tc("品名"),
      field: PRODUCT_GROUP_NAME,
      width: 180,
      resizable: true,
      lockPosition: "left",
      headerClass: [
        "b-header-cell-center",
        "b-cell-border-right-solid-thin-gray",
      ],
      colId: PRODUCT_GROUP_NAME,
      cellClass: ["b-cell-border-right-solid-thin-gray", "text-start"],
    },
    {
      headerName: tc("型式"),
      field: SPEC,
      width: 270,
      resizable: true,
      lockPosition: "left",
      headerClass: [
        "b-cell-border-right-solid-thin-gray",
        "b-header-cell-center",
      ],
      colId: SPEC,
      cellClass: ["b-cell-border-right-solid-thin-gray", "text-start"],
    },
    {
      headerName: tc("単価"),
      field: "unitPrice",
      width: 100,
      resizable: true,
      lockPosition: "left",
      headerClass: [
        "b-cell-border-right-solid-thin-gray",
        "b-header-cell-center",
      ],
      colId: "unitPrice",
      cellClass: ["b-cell-border-right-solid-thin-gray", "text-end"],
      valueFormatter: agJapanCurrencyFormatter,
    },
    {
      headerName: isDone ? tc("実績数量") : tc("推奨数量"),
      field: "quantity",
      width: 100,
      resizable: true,
      lockPosition: "left",
      headerClass: [
        "b-cell-border-right-solid-thin-gray",
        "b-header-cell-center",
      ],
      colId: "quantity",
      cellClass: ["b-cell-border-right-solid-thin-gray", "text-end"],
    },
    {
      headerName: isDone ? tc("実績金額") : tc("推奨金額"),
      field: "subtotal",
      width: 110,
      resizable: true,
      lockPosition: "left",
      headerClass: [
        "b-cell-border-right-solid-thin-gray",
        "b-header-cell-center",
      ],
      colId: "subtotal",
      cellClass: ["b-cell-border-right-solid-thin-gray", "text-end"],
      valueFormatter: agJapanCurrencyFormatter,
    },
  ];

  // モーダルbody部レンダリング
  function modalBody() {
    return (
      <>
        <div className="row mb-2">
          <div className="col-4">
            <div className="input-group">
              <div className="b-input-group-text" style={itemTitleStyle}>
                {tc("実施年度")}
              </div>
              <span className="p-2 form-control" data-cy="実施年度">
                {yearCheckValue === 0 && TIMING_SUBS.includes(Number(timing))
                  ? Number(timing) + 0.5
                  : timing}
                {tc("年")}({Number(vessel ? vessel.year : 0) + Number(timing)}
                {tc("年)")}
              </span>
            </div>
          </div>
          <div className="col-4">
            <div className="input-group">
              <div className="b-input-group-text" style={itemTitleStyle}>
                {tc("得意先名")}
              </div>
              <span className="p-2 form-control" data-cy="得意先名">
                {company?.companyGetResponseSub1.companyName}
              </span>
            </div>
          </div>
          <div className="col-4">
            <div className="input-group">
              <div className="b-input-group-text" style={itemTitleStyle}>
                {tc("船名")}
              </div>
              <span className="p-2 form-control" data-cy="船名">
                {vessel?.vesselName}
              </span>
            </div>
          </div>
        </div>
        <div
          className="ag-theme-alpine w-100 mx-auto b-grid-outer"
          style={{ height: 450 }}
          data-cy="部品交換内容グリッド"
        >
          <AgGridReact
            rowHeight={rowHeight}
            columnDefs={columnDefs}
            rowData={gridPartsReplaceList}
            alwaysShowHorizontalScroll={true}
          />
        </div>
      </>
    );
  }

  // モーダルfooter部レンダリング
  function modalFooter() {
    return (
      <>
        <Button
          className="b-btn-primary"
          onClick={outputExcel}
          data-cy="部品交換内容出力ボタン"
        >
          {tc("部品交換内容出力")}
        </Button>
        <Button
          className="b-btn-close"
          onClick={handleClose}
          data-cy="Closeボタン"
        >
          Close
        </Button>
      </>
    );
  }

  // 部品交換内容Excel出力
  function outputExcel() {
    // 部品交換内容一覧取得API呼び出し
    api
      .get(
        `/api/v1/vessels/${vessel?.imoNo}/companies/${company?.companyGetResponseSub1.companyCode}/products-excel/${timing}?yearCheckValue=${yearCheckValue}`,
        { responseType: "arraybuffer" }
      )
      .then((it) => {
        const excel = new Blob([it.data]);

        //aタグ作成
        const a = document.createElement("a");
        const date = new Date();
        //日付フォーマットをyyyyMMddに
        const outputDate =
          String(date.getFullYear()) +
          String(date.getMonth() + 1).padStart(2, "0") +
          String(date.getDate()).padStart(2, "0");

        // ダウンロードされるファイル名
        a.download = FILE_NAME + outputDate + ".xlsx";
        a.href = URL.createObjectURL(excel);
        // ダウンロード開始
        a.click();
        splashMessage.show("I055");
      })
      .catch((error) => {
        if (getBizError(error)) {
          //対応するエラーメッセージを表示
          showDialog({ error });
        }
      });
  }

  // レンダリング
  return (
    <Modal
      size="xl"
      show={show}
      onHide={handleClose}
      //scrollable
      data-cy="部品交換内容一覧モーダル"
    >
      <Modal.Header closeButton>
        <Modal.Title>{tc("部品交換内容一覧")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{modalBody()}</Modal.Body>
      <Modal.Footer>{modalFooter()}</Modal.Footer>
    </Modal>
  );
});

export default PartsReplaceList;
