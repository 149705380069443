import {
  CellValueChangedEvent,
  FirstDataRenderedEvent,
  ICellRendererParams,
} from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import axios from "axios";
import {
  ChangeEvent,
  forwardRef,
  useCallback,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { Button, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { HiOutlineTrash } from "react-icons/hi";
import { IoCloseCircleOutline } from "react-icons/io5";
import { VscEye, VscEyeClosed } from "react-icons/vsc";
import Select, { SingleValue } from "react-select";
import AsyncSelect from "react-select/async";
import { useSplashMessage } from "src/hook/useSplashMessage";
import { UsersGetResponseSub } from "src/model/api/response/UsersGetResponseSub";
import { UsersPostRequestSub2 } from "src/model/api/response/UsersPostRequestSub2";

import { useAuthUserContext } from "../../context/AuthUser";
import { useDialog } from "../../context/DialogProvider";
import useApi from "../../hook/useApi";
import { useS3 } from "../../hook/useS3";
import { UserPasswordPostResponse } from "../../model/api/request/UserPasswordPostResponse";
import { ClassValuesGetResponse } from "../../model/api/response/ClassValuesGetResponse";
import { CompanyGetResponseSub2 } from "../../model/api/response/CompanyGetResponseSub2";
import { CompanySuggestionsGetResponse } from "../../model/api/response/CompanySuggestionsGetResponse";
import { UserVesselsGetResponse } from "../../model/api/response/UserVesselsGetResponse";
import { UsersGetResponse } from "../../model/api/response/UsersGetResponse";
import { UsersPostRequest } from "../../model/api/response/UsersPostRequest ";
import { UsersPostRequestSub } from "../../model/api/response/UsersPostRequestSub";
import { defaultColDef } from "../../util/AgGridUtil";
import { TextConst } from "../../util/Constant";
import { SelectOption, defaultSelectStyle } from "../../util/SelectUtil";
import { convertSha256 } from "../../util/ecUtil";
import RequireMark from "../common/RequireMark";
import TooltipIcon from "../common/TooltipIcon";

// 公開する関数の定義
export interface UserDetailHandles {
  show(
    userId?: number | undefined,
    status?: string | undefined,
    userBemacFlg?: boolean | undefined
  ): void;
}
type saveButtonName = "更新" | "顧客更新";
type Props = {
  onClickSaveButton?: (buttonName: saveButtonName) => void;
};
// 画面ステータス
export const statusMasterBemac = "0"; //マスター管理_BEMAC
export const statusMasterCustomer = "1"; //マスター管理_顧客
export const statusUpdate = "2"; //更新
export const statusBrowse = "3"; //閲覧
export const statusPasswordChange = "4"; //パスワード変更

//定数宣言
const PASS_NO_MESSAGE = "　";
const PASS_TEMPORARY_MESSAGE =
  "※設定した仮パスワードは必ず控え、ユーザへ伝えてください。";
const PASS_ADMIN_MESSAGE =
  "※管理者が設定したパスワードです。パスワードの変更をお願い致します。（変更しなくてもECサイトの利用は可能です）";
const PASS_BTN_TEMPORARY = "仮パスワード";
const PASS_BTN_CHECK_TEMPORARY = "仮パスワード確認用";
const PASS_BTN = "新パスワード";
const PASS_BTN_CHECK = "新パスワード確認用";

const REGISTER_BTN_REGISTER = "登録";
const REGISTER_BTN_CUSTOMER_REGISTER = "顧客登録";
const REGISTER_BTN_UPDATE = "更新";
const REGISTER_BTN_CUSTOMER_UPDATE = "顧客更新";

const PASS_TEMPORARY = "0";
const PASS_EDIT = "1";
const PASS_CANCEL = "2";
const PASS_CHANGE_MODE = "3";
//ユーザ
const USER = "ユーザ";
const TITLE_BEMAC_NEW = "ユーザ詳細（BEMACユーザ情報登録）";
const TITLE_CUSTOMER_NEW = "ユーザ詳細（顧客情報登録）";
const TITLE_BEMAC_UPDATE = "ユーザ詳細（BEMACユーザ情報更新）";
const TITLE_CUSTOMER_UPDATE = "ユーザ詳細（顧客情報更新）";
const TITLE_BEMAC_VIEW = "ユーザ詳細（BEMACユーザ）";
const TITLE_CUSTOMER_VIEW = "ユーザ詳細（顧客）";

//ユーザ画像URL
const USER_PICTURE_URL = "user-image/";
// デフォルト画像
const DEFAULT_PICTURE = `${process.env.PUBLIC_URL}/user-image-default.png`;

//主処理
const UserDetail = forwardRef<UserDetailHandles, Props>((props: Props, ref) => {
  // メッセージ
  const { t } = useTranslation();
  // 翻訳
  const { t: tc } = useTranslation("UserDetail");
  // API使用宣言
  const api = useApi();

  // エリアごとのCSSクラス
  const areaClass = "p-1 bg-white b-detail-items";

  // state宣言
  //ユーザID
  const [userId, setUserId] = useState<number | undefined>();
  //開く対象のBEMACユーザフラグ
  const [userBemacFlg, setUserBemacFlg] = useState<boolean>();
  //画面
  const [status, setStatus] = useState<string | undefined>();

  //ユーザデータ
  const [userData, setUserData] = useState<UsersGetResponse>();
  const [shipListDatas, setShipListDatas] = useState<UserVesselsGetResponse[]>(
    []
  );
  const [deliveryDatas, setDeliveryDatas] = useState<UsersPostRequestSub2[]>(
    []
  );
  //ユーザ担当船更新日時
  const [companyVesselUserUpdateDateTime, setCompanyVesselUserUpdateDateTime] =
    useState<Date | undefined>();

  // ユーザ担当船0件時のテキスト
  const [noRowsText, setNoRowsText] = useState(TextConst.GRID_NO_DATA);

  // ボタン名
  const [registerBtnText, setRegisterBtnText] = useState("");

  // 項目パラメータ
  //BEMACユーザフラグ
  const [flgBemacUser, setFlgBemacUser] = useState(true);
  //閲覧モードフラグ
  const [flgView, setFlgView] = useState(true);
  //パスワード：表示
  const [passView, setPassView] = useState(false);
  //パスワード編集
  const [passEdit, setPassEdit] = useState(PASS_TEMPORARY);

  //権限：編集可否
  const [userTypeDisabled, setUserTypeDisabled] = useState(false);
  //得意先：編集可否
  const [companyDisabled, setCompanyDisabled] = useState(false);

  //スーパーユーザ：表示非表示
  const [superUserDisabled, setSuperUserDisabled] = useState(true);
  //選択：表示非表示
  const [selectView, setSelectView] = useState(false);
  //船舶一覧の横幅
  const [shipListGridWidth, setShipListGridWidth] = useState(530);
  //権限
  const [userCategory, setUserCategory] = useState("一般");
  //画面タイトル
  const [title, setTitle] = useState(" ");
  //パスワードメッセージ
  const [passMessage, setPassMessage] = useState(" ");
  //パスワードボタン名
  const [passBtnName, setPassBtnName] = useState(" ");
  //パスワード確認ボタン名
  const [passBtnNameCheck, setPassBtnNameCheck] = useState(" ");
  //ユーザ分類
  const [userCategoryTgl, setUserCategoryTgl] = useState(false);
  //自己紹介
  const [introductionTxt, setIntroductionTxt] = useState("");
  //スーパーユーザ
  const [superUserTgl, setSuperUserTgl] = useState(false);
  //商社
  const [tradingFlg, setTradingFlg] = useState(false);
  //フィルター
  const [quick, setQuick] = useState("");
  //パスワードマスタ更新日時
  const [mUserPasswordUpdateDateTime, setMUserPasswordUpdateDateTime] =
    useState<Date | undefined>();
  //プレースフォルダType
  const [placeholderType, setPlaceholderType] = useState("");
  //プレースフォルダType or Select
  const [placeholderTypeOrSelect, setPlaceholderTypeOrSelect] = useState("");
  // 画像データ一時URL
  const [blobURL, setBlobURL] = useState<string>();
  /** S3に保存されている画像ファイル */
  const [currentPicture, setCurrentPicture] = useState<File>();
  /** 新たに選択された画像ファイル */
  const [newPicture, setNewPicture] = useState<File>();
  // モーダル表示状態
  const [show, setShow] = useState(false);
  // スタイル
  // ヘッダ部見出しの幅
  const itemTitleStyle = { width: "12rem" };
  //自己紹介プレースフォルダ
  const [introductionPlaceholder, setintroductionPlaceholder] = useState("");

  // S3使用宣言
  const s3 = useS3();

  // ログインユーザ情報
  const auth = useAuthUserContext();

  // BEMACユーザ分類コンボ
  const [bemacClassOptions, setBemacClassOptions] = useState<
    SelectOption<string>[]
  >([]);

  // 所属部署コンボ
  const [deployOptions, setDeployOptions] = useState<SelectOption<string>[]>(
    []
  );
  const onQuickFilterChanged = useCallback(
    (e: React.FormEvent<HTMLInputElement>) => {
      setQuick((e.target as HTMLInputElement).value);
      gridRef.current?.api.setQuickFilter((e.target as HTMLInputElement).value);
    },
    []
  );
  // 担当船一覧ツールチップメッセージ
  const [shipListGriTooltip, setShipListGriTooltip] = useState("");

  // 検索条件（コンボはundefinedが入る）
  type InputValueType = {
    companyCode: string | undefined;
    companyName: string | undefined;
    employeeNo: string | undefined;
    userId: number | undefined;
    userName: string | undefined;
    bemacUserClassValue: string | undefined;
    bemacUserClassName: string | undefined;
    deploy: string | undefined;
    deployName: string | undefined;
    position: string | undefined;
    userTel: string | undefined;
    userMailAddress: string | undefined;
    bemacFlg: boolean;
    userPassword: string | undefined;
    passwordCheck: string | undefined;
  };
  const [inputValues, setInputValues] = useState<InputValueType>({
    companyCode: "",
    companyName: "",
    employeeNo: "",
    userId: undefined,
    userName: "",
    bemacUserClassValue: "",
    bemacUserClassName: "",
    deploy: "",
    deployName: "",
    position: "",
    userTel: "",
    userMailAddress: "",
    bemacFlg: true,
    userPassword: "",
    passwordCheck: "",
  });

  //ダイアログ仕様宣言
  const showDialog = useDialog();
  //スプラッシュメッセージ使用宣言
  const splashMessage = useSplashMessage();
  // 得意先プルダウン変更
  function companyCodeChange(
    companyCode: string,
    e: SingleValue<SelectOption<string | number>>
  ) {
    inputValues.companyCode = companyCode;
    // 選択値をセット
    setInputValues({ ...inputValues, companyName: e?.label });

    //companyCodeがundefined⇒コンボボックスのクリアのため、
    //管理船舶一覧を初期化する。
    if (companyCode === undefined) {
      const usersPostRequestSub: UserVesselsGetResponse[] = [];
      setShipListDatas(usersPostRequestSub);
      setNoRowsText(TextConst.GRID_NO_DATA);
      return;
    }
    // 得意先単一取得API実行
    api
      .get(`/api/v1/companies/${companyCode}?searchMode=1`)
      .then((it) => {
        // 管理船舶一覧の取得
        const companyGetResponseSub2: CompanyGetResponseSub2[] =
          it.data?.companyGetResponseSub2;

        //ユーザ担当船一覧
        const usersPostRequestSub: UserVesselsGetResponse[] = [];
        companyGetResponseSub2?.forEach((it, index) => {
          usersPostRequestSub.push({
            userSelect: false,
            imoNo: it?.imoNo ?? "",
            vesselName: it?.vesselName ?? "",
            updateDateTime: undefined,
          });
        });
        setShipListDatas(usersPostRequestSub);
        //担当船一覧のメッセージを初期化
        if (usersPostRequestSub.length === 0) {
          setNoRowsText(TextConst.GRID_NO_DATA);
        }
      })
      .catch((error) => {
        // エラー時の処理
        if (error.response.status !== 404) {
          showDialog({ error });
        }
      });
  }

  // BEMACユーザ分類エリアコンボ変更
  function handleChangeSelectBemac(
    name: string,
    e: SingleValue<SelectOption<string | undefined>>
  ) {
    // 選択値をセット
    const value = e?.value;
    inputValues.bemacUserClassValue = value;
    // 選択値をセット
    if (value !== undefined) {
      //コンボボックスから該当する値を取得
      const nameValue = bemacClassOptions.find((it) => it.value === value);
      //取得した値をNameにセット
      setInputValues({ ...inputValues, bemacUserClassName: nameValue?.label });
    } else {
      //nameを初期化
      setInputValues({ ...inputValues, bemacUserClassName: undefined });
    }
  }

  // 所属部署エリアコンボ変更
  function handleChangeSelectDeploy(
    name: string,
    e: SingleValue<SelectOption<string | undefined>>
  ) {
    // 選択値をセット
    const value = e?.value;
    inputValues.deploy = value;

    // 選択値をセット
    if (value !== undefined) {
      const nameValue = deployOptions.find((it) => it.value === value);
      setInputValues({ ...inputValues, deployName: nameValue?.label });
    } else {
      inputValues.deploy = undefined;
      setInputValues({ ...inputValues, deployName: undefined });
    }
  }

  // テキストボックス変更
  function handleChangeInput(e: ChangeEvent<HTMLInputElement>) {
    const name = e.target.name;
    const value = e.target.value;
    // 入力値をセット
    setInputValues({ ...inputValues, [name]: value });
  }
  // テキストボックス変更（半角のみ有効）
  function handleChangeInputHalfWidth(e: ChangeEvent<HTMLInputElement>) {
    const name = e.target.name;
    const value = e.target.value;
    var reg = new RegExp(/^[a-zA-Z0-9!-/:-@¥[-`{-~]*$/);
    // 入力値が半角英数記号の場合セット
    if (reg.test(value ?? "")) {
      setInputValues({ ...inputValues, [name]: value });
    } else {
      if (name === "userTel") {
        setInputValues({ ...inputValues, [name]: inputValues.userTel });
      } else if (name === "userMailAddress") {
        setInputValues({ ...inputValues, [name]: inputValues.userMailAddress });
      } else if (name === "userPassword") {
        setInputValues({ ...inputValues, [name]: inputValues.userPassword });
      } else if (name === "passwordCheck") {
        setInputValues({ ...inputValues, [name]: inputValues.passwordCheck });
      }
    }
  }

  // テキストボックス変更（半角英数字のみ有効）
  function handleChangeInputHalfAlphanumeric(e: ChangeEvent<HTMLInputElement>) {
    const name = e.target.name;
    const value = e.target.value;
    var reg = new RegExp(/^[0-9a-zA-Z]*$/);
    // 入力値が半角英数記号の場合セット
    if (reg.test(value ?? "")) {
      setInputValues({ ...inputValues, [name]: value });
    } else {
      setInputValues({ ...inputValues, [name]: inputValues.employeeNo });
    }
  }

  // 画面初期化
  useImperativeHandle(ref, () => ({
    show(
      userId: number | undefined,
      status: string | undefined,
      userBemacFlg: boolean | undefined
    ) {
      // 初期化
      setUserId(userId);
      setStatus(status);
      setUserBemacFlg(userBemacFlg);

      // BEMACユーザ分類コンボ
      //分類値マスタ取得APIを呼び出し
      api.get(`/api/v1/class/bemac_user_role/values`).then((response) => {
        const bemacRole = response.data.map((it: ClassValuesGetResponse) => {
          return {
            label: it.className,
            value: it.classValue,
          };
        });
        setBemacClassOptions(bemacRole);
      });

      // 所属部署分類コンボ
      //分類値マスタ取得APIを呼び出し
      api.get(`/api/v1/class/bemac_department/values`).then((response) => {
        const bemacRole = response.data.map((it: ClassValuesGetResponse) => {
          return {
            label: it.className,
            value: it.classValue,
          };
        });
        setDeployOptions(bemacRole);
      });

      //担当船一覧の初期化
      const usersPostRequestSub: UserVesselsGetResponse[] = [];
      setShipListDatas(usersPostRequestSub);
      setNoRowsText(TextConst.GRID_NO_DATA);

      //画面の表示、活性の制御
      switch (status) {
        //BEMAC新規作成
        case statusMasterBemac:
          setUserBemacFlg(true);
          //画面タイトルの設定
          setTitle(TITLE_BEMAC_NEW);
          // BEMACユーザの編集
          setFlgBemacUser(true);
          //新規作成時にのみ設定できる項目を設定
          setNewUser();
          //管理者が設定できる項目を設定
          setAdminParam();
          //閲覧モードではない
          setFlgView(false);
          //仮パスワード表示
          setTemporaryPassword();
          //権限の初期表示
          setUserCategoryTgl(false);
          setUserCategory("一般");
          //登録ボタン名称
          setRegisterBtnText(REGISTER_BTN_REGISTER);
          //プレースフォルダー
          changePlaceholder(true);
          //自己紹介のプレースフォルダ設定
          if (userBemacFlg) {
            setintroductionPlaceholder(TextConst.SELF_INTRODUCTION_BEMAC);
          } else {
            setintroductionPlaceholder(TextConst.SELF_INTRODUCTION_CUSTOMER);
          }
          // 送付先一覧
          setDeliveryList([], status);
          break;
        //顧客新規作成
        case statusMasterCustomer:
          setUserBemacFlg(false);
          //画面タイトルの設定
          setTitle(TITLE_CUSTOMER_NEW);
          // 顧客ユーザの編集
          setFlgBemacUser(false);
          //仮パスワード表示
          setTemporaryPassword();
          //閲覧モードではない
          setFlgView(false);
          //管理者が設定できる項目を設定
          setAdminParam();
          if (auth.user().bemacFlg) {
            //新規作成時にのみ設定できる項目を設定
            setNewUser();
          } else {
            //得意先コード
            setCompanyDisabled(true);
            //スーパーユーザ
            setSuperUserDisabled(true);
            inputValues.companyCode = auth.user().companyCode;
            inputValues.companyName = auth.user().companyName;
            setTradingFlg(auth.user().tradingFlg);
            // 得意先単一取得API実行
            api
              .get(`/api/v1/companies/${auth.user().companyCode}?searchMode=1`)
              .then((it) => {
                // 管理船舶一覧の取得
                const companyGetResponseSub2: CompanyGetResponseSub2[] =
                  it.data?.companyGetResponseSub2;

                //ユーザ担当船一覧
                const usersPostRequestSub: UserVesselsGetResponse[] = [];
                companyGetResponseSub2?.forEach((it, index) => {
                  usersPostRequestSub.push({
                    userSelect: false,
                    imoNo: it?.imoNo ?? "",
                    vesselName: it?.vesselName ?? "",
                    updateDateTime: undefined,
                  });
                });
                setShipListDatas(usersPostRequestSub);
                //担当船一覧のメッセージを初期化
                if (usersPostRequestSub.length === 0) {
                  setNoRowsText(TextConst.GRID_NO_DATA);
                }
              })
              .catch((error) => {
                // エラー時の処理
                showDialog({ error });
              });
          }
          //権限の初期表示
          setUserCategoryTgl(false);
          setUserCategory("一般");
          //登録ボタン名称
          setRegisterBtnText(REGISTER_BTN_CUSTOMER_REGISTER);
          //プレースフォルダー
          changePlaceholder(true);
          //自己紹介のプレースフォルダ設定
          if (userBemacFlg) {
            setintroductionPlaceholder(TextConst.SELF_INTRODUCTION_BEMAC);
          } else {
            setintroductionPlaceholder(TextConst.SELF_INTRODUCTION_CUSTOMER);
          }
          // 送付先一覧
          setDeliveryList([], status);
          break;
        //更新
        case statusUpdate:
          if (userBemacFlg) {
            //画面タイトルを設定
            setTitle(TITLE_BEMAC_UPDATE);
            //登録ボタン名称
            setRegisterBtnText(REGISTER_BTN_UPDATE);
          } else {
            //画面タイトルを設定
            setTitle(TITLE_CUSTOMER_UPDATE);
            //登録ボタン名称
            setRegisterBtnText(REGISTER_BTN_CUSTOMER_UPDATE);
          }
          //閲覧モードではない
          setFlgView(false);
          //新規作成以外で編集できない項目を非活性に
          setNotNewUser();

          // BEMACユーザの場合スーパーユーザの設定変更可能
          if (auth.user().bemacFlg) {
            setSuperUserDisabled(false);
          }
          //更新モードのパスワード初期表示設定
          setPassView(false);
          setPassEdit(PASS_CANCEL);
          //管理者が設定できる項目を設定
          if (auth.user().adminFlg) {
            setAdminParam();
            //船舶一覧
            setSelectView(true);
          } else {
            setNotAdminParam();
            //船舶一覧
            setSelectView(false);
          }
          //データ検索
          getUserData(userId, userBemacFlg, status);
          //プレースフォルダー
          changePlaceholder(true);
          //自己紹介のプレースフォルダ設定
          if (userBemacFlg) {
            setintroductionPlaceholder(TextConst.SELF_INTRODUCTION_BEMAC);
          } else {
            setintroductionPlaceholder(TextConst.SELF_INTRODUCTION_CUSTOMER);
          }
          break;
        //閲覧モード
        case statusBrowse:
          //画面タイトルを設定
          if (userBemacFlg) {
            setTitle(TITLE_BEMAC_VIEW);
          } else {
            setTitle(TITLE_CUSTOMER_VIEW);
          }
          //データ検索
          getUserData(userId, userBemacFlg, statusBrowse);

          setFlgBemacUser(userBemacFlg ?? false);
          //項目を非活性に
          setNotNewUser();
          setFlgView(true);
          setPassView(false);
          //船舶一覧の長さ
          setShipListGridWidth(480);
          //権限
          setUserTypeDisabled(true);
          //船舶一覧
          setSelectView(false);
          //プレースフォルダー
          changePlaceholder(false);
          break;
        //パスワード変更モード
        case statusPasswordChange:
          //画面タイトルを設定
          if (userBemacFlg) {
            setTitle(TITLE_BEMAC_UPDATE);
          } else {
            setTitle(TITLE_CUSTOMER_UPDATE);
          }
          //データ検索
          getUserData(userId, userBemacFlg, status);
          //項目を非活性に
          setNotNewUser();
          //閲覧モード
          setFlgView(true);
          //船舶一覧の長さ
          setShipListGridWidth(480);
          //パスワードエリアの設定
          setPassView(true);
          //パスワード入力エリアのメッセージ表示を変更
          setPassMessage(PASS_ADMIN_MESSAGE);
          setPassEdit(PASS_CHANGE_MODE);
          setPassBtnName(PASS_BTN);
          setPassBtnNameCheck(PASS_BTN_CHECK);
          //権限
          setUserTypeDisabled(true);
          //船舶一覧
          setSelectView(false);
          //プレースフォルダー
          changePlaceholder(false);
          break;
      }
      //担当船一覧のツール管理者が設定できる項目を設定
      if (auth.user().adminFlg) {
        setShipListGriTooltip("T015");
      } else {
        setShipListGriTooltip("T007");
      }

      setShow(true);
    },
  }));
  // 得意先コンボ（得意名キー入力ごとにAPI通信）
  const companyOptions = (inputValue: string) => {
    const companyName = encodeURIComponent(inputValue);
    return api
      .get(`/api/v1/company-suggestions?companyName=${companyName}`)
      .then((it) => {
        return it.data.map((row: CompanySuggestionsGetResponse) => {
          return {
            label: row.companyName,
            value: row.companyCode,
          };
        });
      });
  };

  // blobURLを再生成
  function resetBlobUrl(newFile?: File) {
    if (blobURL) {
      // メモリリーク防止のため生成済みのURLを解放
      URL.revokeObjectURL(blobURL);
    }
    if (newFile) {
      setBlobURL(URL.createObjectURL(newFile));
    } else {
      setBlobURL(undefined);
    }
  }

  // S3からファイルの実データを取得
  function loadPictureFile(url: string, objectKey: string) {
    axios.get<Blob>(url, { responseType: "blob" }).then((blobResponse) => {
      if (blobResponse) {
        const fileName = objectKey.substring(objectKey.lastIndexOf("/") + 1);
        const newFile: File = new File([blobResponse.data], fileName, {
          type: blobResponse.headers["content-type"],
        });
        setCurrentPicture(newFile);
        resetBlobUrl(newFile);
      }
    });
  }
  // ユーザ画像ファイル選択処理
  function selectFile(e: React.ChangeEvent<HTMLInputElement>) {
    if (e.currentTarget.files?.length) {
      // 取得したファイルを画面に設定する。
      const file = e.currentTarget.files![0];
      setNewPicture(file);
      resetBlobUrl(file);
    }
  }

  // 添付ファイルをS3に登録
  function saveFile(userId: number) {
    const upload = () => {
      if (newPicture) {
        // 登録ファイルのURL
        const uploadURL = `${USER_PICTURE_URL}${userId}/${newPicture.name}`;
        s3.putS3File(uploadURL, newPicture, false);
      }
    };
    // S3から削除（登録済みファイルがあり、新たなファイルがあるか画像非表示になっている場合削除）
    if (currentPicture && (newPicture || !blobURL)) {
      // 削除ファイルのURL
      const deleteURL = `${USER_PICTURE_URL}${userId}/${currentPicture.name}`;
      s3.deleteS3File(deleteURL, false).then((it) => {
        // 削除後にS3にアップロード
        upload();
      });
    } else {
      // S3にアップロード
      upload();
    }
  }

  // ユーザデータ取得
  function getUserData(
    userId: number | undefined,
    userBemacFlg: boolean | undefined,
    status: string | undefined
  ) {
    const queryParams: string[] = [];
    queryParams.push(`userId=${userId}`);
    queryParams.push(`bemacFlg=${userBemacFlg}`);
    queryParams.push(`getDelUser=true`);
    const query = queryParams.join("&");
    //ユーザリスト取得APIを呼び出し
    api
      .get(`/api/v1/users?${query}`)
      .then((response) => {
        // ユーザ情報の取得
        const usersGetResponse: UsersGetResponse[] = response.data;

        // 検索結果をセット
        setUserData(usersGetResponse[0]);
        // 検索結果を画面に表示
        setUserDetail(usersGetResponse[0]);
        // 送付先一覧
        setDeliveryList(usersGetResponse[0].usersGetResponseSub, status);

        //ユーザ担当船一覧の作成
        const searchMode = status === statusBrowse ? "0" : "1";
        setShipList(searchMode, userId);

        //画像データを取得
        const url = USER_PICTURE_URL + usersGetResponse[0].userId + "/";
        s3.getS3FileList(url).then((res1) => {
          const s3FileList = res1.data;
          if (s3FileList.length > 0) {
            s3.getS3PresignedUrl("GET", s3FileList[0]).then((res2) => {
              const presignedUrl = res2.data;
              loadPictureFile(presignedUrl, s3FileList[0]);
            });
          }
        });

        // 削除されたユーザの場合、メッセージ表示
        if (usersGetResponse[0].delFlg) {
          showDialog({
            id: "I056",
            args: [USER],
            confirm: false,
          });
        }
      })
      .catch((error) => {
        // エラー時の処理
        showDialog({ error });
      });
  }

  // 担当船一覧取得
  function setShipList(searchMode: string, userId: number | undefined) {
    const queryParams: string[] = [];
    queryParams.push(`searchMode=${searchMode}`);
    const query = queryParams.join("&");
    //ユーザリスト取得APIを呼び出し
    api
      .get(`/api/v1/users/${userId}/vessels?${query}`)
      .then((response) => {
        // ユーザ情報の取得
        const userVesselsGetResponse: UserVesselsGetResponse[] = response.data;
        // 検索結果をセット
        setShipListDatas(userVesselsGetResponse);
        // ユーザ担当船リストの更新日時を取得する
        if (userVesselsGetResponse.length > 0) {
          setCompanyVesselUserUpdateDateTime(
            userVesselsGetResponse[0].updateDateTime
          );
        }
        //担当船一覧のメッセージを初期化
        if (userVesselsGetResponse.length === 0) {
          setNoRowsText(TextConst.GRID_INITIAL);
        }
      })
      .catch((error) => {
        // エラー時の処理
        showDialog({ error });
      });
  }

  // ユーザ登録
  function registerUser() {
    // 新規登録の場合、仮パスワードチェック
    if (status === statusMasterBemac || status === statusMasterCustomer) {
      const messageId = checkPassword();
      if (messageId !== undefined) {
        showDialog({
          id: messageId,
          confirm: false,
        });
        return;
      }
    }
    // 画像のチェック
    if (userBemacFlg && !blobURL) {
      showDialog({
        id: "E007",
        args: ["写真"],
        confirm: false,
      });
      return;
    }

    //ユーザ担当船一覧
    const usersPostRequestSub: UsersPostRequestSub[] = [];
    gridRef.current?.api.forEachNode((node: any, index: any) => {
      const row: UserVesselsGetResponse = node.data;
      const select = node.isSelected();
      usersPostRequestSub.push({
        select: select,
        imoNo: row?.imoNo,
        vesselName: row?.vesselName,
        companyVesselUserUpdateDateTime: companyVesselUserUpdateDateTime,
      });
    });
    // BEMACユーザの場合はBEMACの会社コードを設定
    if (userBemacFlg) {
      inputValues.companyCode = "00737";
    }
    // 送付先一覧
    const usersPostRequestSub2: UsersPostRequestSub2[] = [];
    gridRefDelivery.current?.api.forEachNode((node: any, index: any) => {
      const row: UsersGetResponseSub = node.data;
      if (
        row.name !== "" ||
        row.postalCode !== "" ||
        row.address !== "" ||
        row.tel !== ""
      ) {
        usersPostRequestSub2.push({
          name: row.name,
          postalCode: row.postalCode,
          address: row.address,
          tel: row.tel,
        });
      }
    });

    // 入力されたパスワードをハッシュ化
    const hashedPassword = convertSha256(inputValues?.userPassword ?? "");
    //リクエストの作成
    const request: UsersPostRequest = {
      bemacFlg: userBemacFlg ?? false,
      adminFlg: userCategoryTgl,
      employeeNo: inputValues?.employeeNo,
      userName: inputValues?.userName,
      userId: inputValues.userId,
      bemacUserClassValue: inputValues?.bemacUserClassValue,
      departmentClassValue: inputValues.deploy,
      position: inputValues.position,
      userTel: inputValues.userTel,
      mailAddress: inputValues.userMailAddress,
      selfIntroduction: introductionTxt,
      suFlg: superUserTgl,
      companyCode: inputValues.companyCode,
      muserUpdateDateTime: userData?.muserUpdateDateTime,
      password: hashedPassword,
      delFlg: false,
      usersPostRequestSub: usersPostRequestSub,
      usersPostRequestSub2: usersPostRequestSub2,
    };
    // ユーザ登録APIの実行
    api
      .post(`/api/v1/users?`, request)
      .then((response) => {
        // ユーザ情報の取得
        const usersGetResponse: UsersGetResponse = response.data;
        // 登録したユーザIDを表示
        inputValues.userId = usersGetResponse.userId;
        // 画像の登録
        saveFile(usersGetResponse.userId!);
        //完了メッセージの表示
        //画面を初期化して閉じる
        closeInit();
        if (status === statusUpdate) {
          //更新完了
          splashMessage.show("I046");
          //更新の場合、一覧画面の検索を呼び出す
          afterSaved("更新");
        } else {
          //登録完了
          splashMessage.show("I035");
        }
        return;
      })
      .catch((error) => {
        // エラー時の処理
        if (error.response.status === 404) {
          showDialog({ id: "E051" });
        } else {
          showDialog({ error });
        }
      });
  }

  // パスワード登録
  function registerPassword() {
    showDialog({
      id: "I004",
      confirm: true,
      callback(isOk) {
        if (isOk) {
          // OKが押された場合
          // パスワード入力チェック
          const messageId = checkPassword();
          if (messageId !== undefined) {
            showDialog({
              id: messageId,
              confirm: false,
            });
            return;
          }
          // 入力されたパスワードをハッシュ化
          const hashedPassword = convertSha256(inputValues?.userPassword ?? "");
          //リクエストの作成
          const request: UserPasswordPostResponse = {
            newPassword: hashedPassword,
            muserPasswordUpdateDateTime: mUserPasswordUpdateDateTime,
          };
          // パスワード登録APIの実行
          api
            .post(`/api/v1/users/${inputValues?.userId}/password?`, request)
            .then((response) => {
              const data: UserPasswordPostResponse = response.data;

              //完了メッセージの表示
              splashMessage.show("I046");
              //パスワード変更モードの場合、画面を閉じる
              if (passEdit === PASS_CHANGE_MODE) {
                closeInit();
              }
              //画面コントロールを元に戻す
              returnPasswordMode();
              //パスワードマスタ最新更新日を更新
              setMUserPasswordUpdateDateTime(data.muserPasswordUpdateDateTime);
              return;
            })
            .catch((error) => {
              if (error.response.status === 404) {
                showDialog({ id: "E051" });
              } else {
                // エラー時の処理
                showDialog({ error });
              }
            });
        }
      },
    });
  }
  //登録完了後処理
  function afterSaved(buttonName: saveButtonName) {
    if (props.onClickSaveButton) {
      props.onClickSaveButton(buttonName);
    }
    // ユーザ詳細画面の更新を通知する
    const event = new Event("UserDetailSave");
    document.dispatchEvent(event);
  }

  // 仮パスワード表示
  function setTemporaryPassword() {
    //パスワード：表示
    setPassView(true);
    setPassMessage(PASS_TEMPORARY_MESSAGE);
    setPassBtnName(PASS_BTN_TEMPORARY);
    setPassBtnNameCheck(PASS_BTN_CHECK_TEMPORARY);
    setPassEdit(PASS_TEMPORARY);
  }
  // パスワード変更
  function setEditPassword() {
    //パスワード：非表示
    setPassView(true);
    setPassEdit(PASS_EDIT);
    //編集ユーザが自分自身かどうかでパスワード入力エリアのメッセージ表示を変更する。
    if (userId === auth.user().userId) {
      setPassMessage(PASS_NO_MESSAGE);
      setPassBtnName(PASS_BTN);
      setPassBtnNameCheck(PASS_BTN_CHECK);
    } else {
      setPassMessage(PASS_TEMPORARY_MESSAGE);
      setPassBtnName(PASS_BTN_TEMPORARY);
      setPassBtnNameCheck(PASS_BTN_CHECK_TEMPORARY);
    }
    //パスワード以外の項目を非活性に
    setFlgView(true);
    //権限
    setUserTypeDisabled(true);
    //船舶一覧
    setSelectView(false);
    //プレースフォルダー
    changePlaceholder(false);
  }
  // パスワードの変更をやめる
  function setCancelPassword() {
    showDialog({
      id: "I001",
      confirm: true,
      callback(isOk) {
        if (isOk) {
          // OKが押された場合
          returnPasswordMode();
        }
      },
    });
  }
  // パスワード変更モードの画面コントロールを元に戻す
  function returnPasswordMode() {
    //パスワード：非表示
    setPassView(false);
    setPassMessage("");
    setPassEdit(PASS_CANCEL);
    setFlgView(false);
    //パスワード入力初期化
    inputValues.userPassword = "";
    inputValues.passwordCheck = "";
    setIsRevealPassword(false);
    setIsRevealPasswordCheck(false);
    //管理者が設定できる項目を設定
    if (auth.user().adminFlg) {
      setAdminParam();
    }
    //プレースフォルダー
    changePlaceholder(true);
  }

  // 新規作成時のみ設定できる項目を設定
  function setNewUser() {
    //得意先コード
    setCompanyDisabled(false);
    //スーパーユーザ
    setSuperUserDisabled(false);
  }

  // 新規作成時以外で設定できない項目を非活性に
  function setNotNewUser() {
    //得意先コード
    setCompanyDisabled(true);
    //スーパーユーザ
    setSuperUserDisabled(true);
  }

  // 管理者のみ設定できる項目を設定
  function setAdminParam() {
    //権限
    setUserTypeDisabled(false);
    //船舶一覧
    setSelectView(true);
  }
  // 管理者のみ設定できる項目を非活性にする
  function setNotAdminParam() {
    //権限
    setUserTypeDisabled(true);
    //船舶一覧
    setSelectView(true);
  }

  // ユーザ詳細画面の各項目に値をセット
  function setUserDetail(usersGetResponse: UsersGetResponse) {
    // 得意先コード
    inputValues.companyCode = usersGetResponse.companyCode;
    // 得意先名
    inputValues.companyName = usersGetResponse.companyName ?? "";
    // 社員番号
    inputValues.employeeNo = usersGetResponse.employeeNo ?? "";
    // ユーザ名
    inputValues.userName = usersGetResponse.userName;
    // ユーザID
    inputValues.userId = usersGetResponse.userId;
    // BEMACユーザ分類
    inputValues.bemacUserClassValue =
      usersGetResponse.bemacUserClassValue ?? "";
    // BEMACユーザ分類表示名称
    inputValues.bemacUserClassName =
      usersGetResponse.bemacUserClassValueName ?? "";
    // 所属部署
    inputValues.deploy = usersGetResponse.departmentClassValue ?? "";
    // 所属部署名称
    inputValues.deployName = usersGetResponse.departmentClassValueName ?? "";
    // 役職
    inputValues.position = usersGetResponse.position ?? "";
    // 電話番号
    inputValues.userTel = usersGetResponse.userTel ?? "";
    // Mail
    inputValues.userMailAddress = usersGetResponse.mailAddress;
    // BEMACユーザ分類
    inputValues.bemacFlg = usersGetResponse.bemacFlg;
    //BEMAC_顧客ユーザの画面項目設定
    setFlgBemacUser(usersGetResponse.bemacFlg);
    // ユーザ権限
    if (usersGetResponse.adminFlg) {
      // 管理者
      setUserCategoryTgl(true);
      setUserCategory(usersGetResponse.adminValue);
    } else {
      // 一般ユーザ
      setUserCategoryTgl(false);
      setUserCategory(usersGetResponse.adminValue);
    }
    // 商社
    setTradingFlg(usersGetResponse.tradingFlg);
    // スーパーユーザ
    setSuperUserTgl(usersGetResponse.suFlg);
    // 自己紹介
    setIntroductionTxt(usersGetResponse.selfIntroduction);
    //パスワードマスタ更新日時
    setMUserPasswordUpdateDateTime(
      usersGetResponse.muserPasswordUpdateDateTime
    );
  }

  function setDeliveryList(
    list: UsersGetResponseSub[] | null,
    status: string | undefined
  ) {
    const newList: UsersPostRequestSub2[] =
      list?.map((row) => {
        return {
          name: row.name ?? "",
          postalCode: row.postalCode ?? "",
          address: row.address ?? "",
          tel: row.tel ?? "",
        };
      }) ?? [];

    if (status !== statusBrowse && status !== statusPasswordChange) {
      // 参照モードでもパスワード変更モードでも無い場合は新規行を追加しておく
      addNewRow(newList);
    }

    setDeliveryDatas(newList);
  }

  //ユーザ分類トグル押下イベント
  function userCategoryChange() {
    if (userCategoryTgl === false) {
      setUserCategoryTgl(true);
      setUserCategory("管理者");
    } else {
      setUserCategoryTgl(false);
      setUserCategory("一般");
    }
  }
  //スーパーユーザトグル押下イベント
  function superUserChange() {
    if (superUserTgl === false) {
      setSuperUserTgl(true);
    } else {
      setSuperUserTgl(false);
    }
  }
  //プレースフォルダーのテキスト変更
  function changePlaceholder(flgPlaceholderView: boolean) {
    if (flgPlaceholderView) {
      setPlaceholderType(TextConst.INPUT_PLACEHOLDER);
      setPlaceholderTypeOrSelect(TextConst.COMBO_PLACEHOLDER);
      //BEMAC/顧客の自己紹介プレースフォルダ設定
      if (userBemacFlg) {
        setintroductionPlaceholder(TextConst.SELF_INTRODUCTION_BEMAC);
      } else {
        setintroductionPlaceholder(TextConst.SELF_INTRODUCTION_CUSTOMER);
      }
    } else {
      setPlaceholderType("");
      setintroductionPlaceholder("");
      setPlaceholderTypeOrSelect("");
    }
  }

  // モーダルクローズハンドラ
  const handleClose = () => {
    if (status !== statusBrowse) {
      showDialog({
        id: "I001",
        confirm: true,
        callback(isOk) {
          if (isOk) {
            if (status === statusPasswordChange) {
              //リクエストの作成
              const request: UserPasswordPostResponse = {
                newPassword: undefined,
                muserPasswordUpdateDateTime: mUserPasswordUpdateDateTime,
              };
              api
                .post(`/api/v1/users/${auth.user().userId}/password`, request)
                .then((it) => {
                  closeInit();
                })
                .catch((error) => {
                  showDialog({ error });
                });
            }
            // OKが押された場合
            closeInit();
          }
        },
      });
    } else {
      // OKが押された場合
      closeInit();
    }
  };

  //画面を初期化して閉じる
  function closeInit() {
    setShow(false);
    setUserId(undefined);
    setInputValues({
      companyCode: "",
      companyName: "",
      employeeNo: "",
      userId: undefined,
      userName: "",
      bemacUserClassValue: "",
      bemacUserClassName: "",
      deploy: "",
      deployName: "",
      position: "",
      userTel: "",
      userMailAddress: "",
      bemacFlg: false,
      userPassword: "",
      passwordCheck: "",
    });
    const drafftValue: UsersGetResponse = {
      companyCode: "",
      companyName: "",
      employeeNo: "",
      userId: undefined,
      userName: "",
      bemacUserClassValue: "",
      bemacUserClassValueName: "",
      departmentClassValue: "",
      departmentClassValueName: "",
      position: "",
      userTel: "",
      mailAddress: "",
      bemacFlg: false,
      adminFlg: false,
      adminValue: "",
      selfIntroduction: "",
      suFlg: false,
      delFlg: false,
      tradingFlg: false,
      muserUpdateDateTime: undefined,
      muserPasswordUpdateDateTime: undefined,
      usersGetResponseSub: [],
    };
    resetBlobUrl();
    setCurrentPicture(undefined);
    setNewPicture(undefined);
    inputValues.userPassword = "";
    inputValues.passwordCheck = "";
    setIsRevealPassword(false);
    setIsRevealPasswordCheck(false);
    setUserDetail(drafftValue);
    setDeliveryList(null, statusBrowse);
  }
  //登録ボタン押下時の処理
  function onClickRegistButton() {
    let messageId = "I004";
    //BEMACユーザが顧客を作成する時のメッセージ表示
    if (auth.user().bemacFlg && status === statusMasterCustomer) {
      messageId = "I005";
    }
    showDialog({
      id: messageId,
      confirm: true,
      callback(isOk) {
        if (isOk) {
          // OKが押された場合
          registerUser();
        }
      },
    });
  }

  // パスワード表示制御用
  const [isRevealPassword, setIsRevealPassword] = useState(false);
  const togglePassword = () => {
    setIsRevealPassword((prevState) => !prevState);
  };
  // パスワード確認用表示制御
  const [isRevealPasswordCheck, setIsRevealPasswordCheck] = useState(false);
  const togglePasswordCheck = () => {
    setIsRevealPasswordCheck((prevState) => !prevState);
  };

  // パスワードチェック処理
  function checkPassword() {
    // パスワードチェック
    if (inputValues?.userPassword !== inputValues?.passwordCheck) {
      //パスワードの入力内容が一致しません。
      return "E025";
    }

    var reg = new RegExp(/^[a-zA-Z0-9!-/:-@¥[-`{-~]{10,}$/);
    if (!reg.test(inputValues?.userPassword ?? "")) {
      //パスワードは半角10文字以上です。半角英字と半角数字を必ず含めて入力してください。
      return "E063";
    }
    var strArray = (inputValues?.userPassword ?? "").split("");

    let numberBool = false;
    let englishBool = false;
    //半角数字
    var number = new RegExp(/^[0-9]+$/);
    //半角英字
    var english = new RegExp(/^[a-zA-Z]+$/);
    strArray.forEach((node: any, index: any) => {
      if (number.test(node)) {
        numberBool = true;
      } else if (english.test(node)) {
        englishBool = true;
      }
    });
    if (!numberBool || !englishBool) {
      //パスワードは半角10文字以上です。半角英字と半角数字を必ず含めて入力してください。
      return "E063";
    }
  }

  // ×ボタンレンダリング
  function renderDeleteButton() {
    return (
      <IoCloseCircleOutline
        className="hover-pointer"
        size={"1.5rem"}
        style={{
          color: "black",
          backgroundColor: "white",
          position: "absolute",
          right: "5px",
          zIndex: 10,
        }}
        onClick={() => {
          // 今回追加しようとしたファイルを取消
          if (newPicture) {
            setNewPicture(undefined);
          }
          // 表示画像をクリア
          resetBlobUrl();
        }}
      />
    );
  }

  function addNewRow(list: UsersPostRequestSub2[]) {
    list.push({
      name: "",
      postalCode: "",
      address: "",
      tel: "",
    });
  }

  function onCellValueChangedDeliveryGrid(event: CellValueChangedEvent) {
    if (typeof event.newValue === "undefined") {
      // 編集キャンセル時は何もしない
      return;
    }

    // 自身が最終行なら一行追加する
    if (event.rowIndex === deliveryDatas.length - 1) {
      setDeliveryDatas((prev) => {
        const newRows = [...prev];
        addNewRow(newRows);
        return newRows;
      });
    }
  }

  function onClickDeliveryGridDeleteButton(deleteIndex: number) {
    const newRows = deliveryDatas.filter((row, index) => index !== deleteIndex);
    setDeliveryDatas(newRows);
  }

  // 削除ボタンレンダリング
  function rendererDeleteButton(
    rowData: ICellRendererParams<UsersPostRequestSub2>
  ) {
    // 最終行には表示しない
    if (rowData.rowIndex === deliveryDatas.length - 1) {
      return <></>;
    }

    return (
      <span>
        <a
          style={{ color: "black" }}
          href="#"
          onClick={(e) => onClickDeliveryGridDeleteButton(rowData.rowIndex)}
        >
          <div style={{ display: "flex", justifyContent: "center" }}>
            <HiOutlineTrash size={20} style={{ margin: "10 10px" }} />
          </div>
        </a>
      </span>
    );
  }

  // 参照
  const gridRef = useRef<AgGridReact>(null); // 明細

  // グリッドの列定義（担当船一覧）
  const columnDefs = [
    {
      headerName: "",
      colId: "select",
      field: "",
      width: 50,
      checkboxSelection: true,
      headerCheckboxSelection: true,
      filter: true,
      sortable: true,
      hide: !selectView,
    },
    {
      headerName: "",
      field: "userSelect",
      hide: true,
    },
    {
      headerName: "IMO",
      field: "imoNo",
      width: 150,
      filter: true,
      sortable: true,
    },
    {
      headerName: tc("船名"),
      field: "vesselName",
      width: 305,
      cellStyle: { textAlign: "left" },
      filter: true,
      sortable: true,
    },
    {
      headerName: "更新日時",
      field: "updateDateTime",
      hide: true,
    },
  ];

  // 参照
  const gridRefDelivery = useRef<AgGridReact>(null); // 送付先明細

  // グリッドの列定義（送付先一覧）
  const columnDefsDelivery = [
    {
      headerName: tc("宛名"),
      field: "name",
      width: 215,
      editable: !flgView,
      cellClassRules: {
        "b-editable-cell": () => !flgView,
      },
    },
    {
      headerName: tc("郵便番号"),
      field: "postalCode",
      width: 130,
      editable: !flgView,
      cellClassRules: {
        "b-editable-cell": () => !flgView,
      },
    },
    {
      headerName: tc("住所"),
      field: "address",
      width: 480,
      cellEditor: "agLargeTextCellEditor",
      cellEditorPopup: true,
      cellEditorParams: {
        maxLength: 100,
        rows: 5,
      },
      editable: !flgView,
      cellClassRules: {
        "b-editable-cell": () => !flgView,
      },
    },
    {
      headerName: tc("電話番号"),
      field: "tel",
      width: 160,
      editable: !flgView,
      cellClassRules: {
        "b-editable-cell": () => !flgView,
      },
    },
    {
      headerName: tc("削除"),
      colId: "delete",
      width: 80,
      cellRenderer: rendererDeleteButton,
      hide: flgView,
    },
  ];

  // モーダルbody部レンダリング
  function modalBody() {
    return (
      <>
        <div>
          {/* ユーザIDがある */}
          <div>
            <div className={areaClass}>
              <div className="row">
                <div className="col-6">
                  <div className="input-group">
                    {/* 得意先名 */}
                    {!flgBemacUser && (
                      <>
                        <span
                          className="b-input-group-text"
                          style={itemTitleStyle}
                        >
                          {tc("得意先名")}
                          {status === statusMasterCustomer && <RequireMark />}
                        </span>
                        {companyDisabled ? (
                          <input
                            name="companyName"
                            type="text"
                            className="form-control"
                            value={inputValues.companyName ?? ""}
                            disabled={true}
                            data-cy="得意先名テキスト"
                          />
                        ) : (
                          <>
                            <span
                              className="form-control"
                              data-cy="得意先名コンボ"
                            >
                              <AsyncSelect
                                styles={defaultSelectStyle}
                                placeholder={TextConst.COMBO_PLACEHOLDER}
                                isClearable
                                cacheOptions
                                defaultOptions
                                loadOptions={companyOptions}
                                onChange={(e) => {
                                  companyCodeChange(e?.value, e);
                                }}
                                value={
                                  inputValues.companyCode
                                    ? {
                                        label: inputValues.companyName,
                                        value: inputValues.companyCode,
                                      }
                                    : undefined
                                }
                              />
                            </span>
                          </>
                        )}
                      </>
                    )}
                  </div>
                </div>
              </div>
              <div className="row">
                {/* ユーザ分類 */}
                {status !== statusBrowse && (
                  <div className="col-6">
                    <div className="input-group">
                      <span
                        className="b-input-group-text"
                        style={itemTitleStyle}
                      >
                        {tc("権限")}
                      </span>
                      {/* トグル */}
                      <div
                        className={`form-check form-switch mb-0 form-control ${
                          userTypeDisabled ? "disabled" : ""
                        }`}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          lineHeight: "1.5",
                          backgroundColor: userTypeDisabled ? "" : "#f5f5f5",
                        }}
                      >
                        <input
                          className="form-check-input mb-1"
                          style={{
                            marginLeft: "-1rem",
                            fontSize: 20,
                            width: 60,
                          }}
                          disabled={userTypeDisabled}
                          type="checkbox"
                          checked={userCategoryTgl}
                          onChange={userCategoryChange}
                          data-cy="権限トグル"
                        />
                        {status !== statusBrowse && (
                          <div className="mx-4" data-cy="権限値">
                            {tc(userCategory)}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                )}

                {/* スーパーユーザ */}
                {!flgBemacUser && (
                  <div className="col-6">
                    <div className="input-group">
                      <span
                        className="b-input-group-text"
                        style={itemTitleStyle}
                      >
                        {tc("スーパーユーザ")}
                      </span>

                      {/* トグル */}
                      <div
                        className={`form-check form-switch form-control mb-0 ${
                          superUserDisabled ? "disabled" : ""
                        }`}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          lineHeight: "1.5",
                          backgroundColor: superUserDisabled ? "" : "#f5f5f5",
                        }}
                      >
                        <input
                          className="form-check-input mb-1"
                          style={{
                            marginLeft: "-1rem",
                            fontSize: 20,
                            width: 60,
                          }}
                          type="checkbox"
                          disabled={superUserDisabled}
                          checked={superUserTgl}
                          onChange={superUserChange}
                          data-cy="スーパーユーザトグル"
                        />
                        {superUserTgl && (
                          <span className="mx-4" data-cy="スーパーユーザ">
                            {tc("スーパーユーザ")}
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                )}
              </div>

              {/* 社員番号 */}
              {flgBemacUser && (
                <div className="row">
                  <div className="col-6">
                    <div className="input-group">
                      <span
                        className="b-input-group-text"
                        style={itemTitleStyle}
                      >
                        {tc("社員番号")}
                        {passEdit !== PASS_EDIT && !flgView && <RequireMark />}
                      </span>
                      <input
                        name="employeeNo"
                        type="text"
                        className="form-control"
                        disabled={flgView}
                        value={inputValues.employeeNo}
                        onChange={handleChangeInputHalfAlphanumeric}
                        maxLength={4}
                        placeholder={placeholderType}
                        data-cy="社員番号テキスト"
                      />
                    </div>
                  </div>
                </div>
              )}

              <div className="row">
                {/* ユーザ名 */}
                <div className="col-6">
                  <div className="input-group">
                    <span className="b-input-group-text" style={itemTitleStyle}>
                      {tc("ユーザ名")}
                      {passEdit !== PASS_EDIT && !flgView && <RequireMark />}
                    </span>
                    <input
                      name="userName"
                      type="text"
                      className="form-control"
                      disabled={flgView}
                      value={inputValues.userName}
                      onChange={handleChangeInput}
                      maxLength={100}
                      placeholder={placeholderType}
                      data-cy="ユーザ名詳細テキスト"
                    />
                  </div>
                </div>

                {/* ユーザID */}
                <div className="col-6">
                  <div className="input-group">
                    <span className="b-input-group-text" style={itemTitleStyle}>
                      {tc("ユーザID")}
                    </span>
                    <input
                      name="userId"
                      type="text"
                      className="form-control"
                      value={inputValues.userId ?? ""}
                      disabled={true}
                      placeholder={TextConst.AUTOMATIC_NUMBERING}
                      data-cy="ユーザID詳細テキスト"
                    />
                  </div>
                </div>
              </div>

              {flgBemacUser && (
                <div className="row">
                  {/* BEMACユーザ分類 */}
                  <div className="col-6">
                    <div className="input-group">
                      <span
                        className="b-input-group-text"
                        style={itemTitleStyle}
                      >
                        {tc("BEMACユーザ分類")}
                        {passEdit !== PASS_EDIT && !flgView && <RequireMark />}
                      </span>

                      {flgView || !auth.user().adminFlg ? (
                        <input
                          name="bemacUserClassName"
                          type="text"
                          className="form-control"
                          value={inputValues.bemacUserClassName ?? ""}
                          disabled={true}
                          data-cy="BEMACユーザ分類テキスト"
                        />
                      ) : (
                        <span
                          className="form-control"
                          data-cy="BEMACユーザ分類詳細コンボ"
                        >
                          <Select
                            styles={defaultSelectStyle}
                            placeholder={placeholderTypeOrSelect}
                            isClearable
                            options={bemacClassOptions}
                            onChange={(e) => {
                              handleChangeSelectBemac("bemacUserClassValue", e);
                            }}
                            value={
                              inputValues.bemacUserClassValue
                                ? {
                                    label: inputValues.bemacUserClassName,
                                    value: inputValues.bemacUserClassValue,
                                  }
                                : undefined
                            }
                          />
                        </span>
                      )}
                    </div>
                  </div>

                  {/* 所属部署 */}
                  <div className="col-6">
                    <div className="input-group">
                      <span
                        className="b-input-group-text"
                        style={itemTitleStyle}
                      >
                        {tc("所属部署")}
                        {passEdit !== PASS_EDIT && !flgView && <RequireMark />}
                      </span>
                      {flgView ? (
                        <input
                          name="deployName"
                          type="text"
                          className="form-control"
                          value={inputValues.deployName ?? ""}
                          disabled={true}
                          data-cy="所属部署テキスト"
                        />
                      ) : (
                        <span className="form-control" data-cy="所属部署コンボ">
                          <Select
                            styles={defaultSelectStyle}
                            placeholder={placeholderTypeOrSelect}
                            isClearable
                            options={deployOptions}
                            onChange={(e) => {
                              handleChangeSelectDeploy("deploy", e);
                            }}
                            value={
                              inputValues.deploy
                                ? {
                                    label: inputValues.deployName,
                                    value: inputValues.deploy,
                                  }
                                : undefined
                            }
                          />
                        </span>
                      )}
                    </div>
                  </div>
                </div>
              )}

              {/* 役職 */}
              <div className="row">
                <div className="col-6">
                  <div className="input-group">
                    <span className="b-input-group-text" style={itemTitleStyle}>
                      {tc("役職")}
                      {flgBemacUser && passEdit !== PASS_EDIT && !flgView && (
                        <RequireMark />
                      )}
                    </span>
                    <input
                      name="position"
                      type="text"
                      className="form-control"
                      disabled={flgView}
                      value={inputValues.position}
                      onChange={handleChangeInput}
                      maxLength={50}
                      placeholder={placeholderType}
                      data-cy="役職テキスト"
                    />
                  </div>
                </div>
              </div>

              <div className="row">
                {/* 電話番号 */}
                <div className="col-6">
                  <div className="input-group">
                    <span className="b-input-group-text" style={itemTitleStyle}>
                      {tc("電話番号")}
                      {passEdit !== PASS_EDIT && !flgView && !flgBemacUser && (
                        <RequireMark />
                      )}
                    </span>
                    <input
                      name="userTel"
                      type="text"
                      className="form-control"
                      disabled={flgView}
                      value={inputValues.userTel}
                      onChange={handleChangeInputHalfWidth}
                      maxLength={13}
                      placeholder={placeholderType}
                      data-cy="電話番号テキスト"
                    />
                  </div>
                </div>

                {/* Mail */}
                <div className="col-6">
                  <div className="input-group">
                    <span className="b-input-group-text" style={itemTitleStyle}>
                      Mail
                      {passEdit !== PASS_EDIT && !flgView && <RequireMark />}
                    </span>
                    <input
                      name="userMailAddress"
                      type="text"
                      className="form-control"
                      value={inputValues.userMailAddress}
                      onChange={handleChangeInputHalfWidth}
                      disabled={flgView}
                      maxLength={256}
                      placeholder={placeholderType}
                      data-cy="Mail詳細テキスト"
                    />
                  </div>
                </div>
              </div>

              {passView && (
                <div className="row">
                  <div style={{ color: "red" }} data-cy="パスワード備考">
                    {tc(passMessage)}
                  </div>
                  {/* パスワード */}
                  <div className="col-6">
                    <div className="input-group">
                      <span
                        className="b-input-group-text"
                        style={itemTitleStyle}
                        data-cy="パスワードラベル"
                      >
                        {tc(passBtnName)}
                        <RequireMark />
                      </span>
                      <input
                        placeholder={TextConst.INPUT_PASSWORD}
                        type={isRevealPassword ? "text" : "password"}
                        name="userPassword"
                        className="form-control"
                        style={{
                          borderBottomRightRadius: "0.25rem",
                          borderTopRightRadius: "0.25rem",
                        }}
                        value={inputValues.userPassword}
                        onChange={handleChangeInputHalfWidth}
                        maxLength={30}
                        data-cy="パスワードテキスト"
                        autoComplete="new-password"
                      />
                      <span
                        onClick={togglePassword}
                        role="presentation"
                        data-cy="パスワードマスク設定ボタン"
                      >
                        {isRevealPassword ? (
                          <VscEye
                            size={"1.3rem"}
                            style={{
                              position: "absolute",
                              top: "0.6rem",
                              left: "31rem",
                            }}
                            data-cy="パスワードマスクボタン"
                          />
                        ) : (
                          <VscEyeClosed
                            size={"1.3rem"}
                            style={{
                              position: "absolute",
                              top: "0.6rem",
                              left: "31rem",
                            }}
                            data-cy="パスワードマスク解除ボタン"
                          />
                        )}
                      </span>
                    </div>
                  </div>

                  {/* パスワード確認用 */}
                  <div className="col-6">
                    <div className="input-group">
                      <span
                        className="b-input-group-text"
                        style={itemTitleStyle}
                        data-cy="パスワード確認用ラベル"
                      >
                        {tc(passBtnNameCheck)}
                        <RequireMark />
                      </span>
                      <input
                        placeholder={TextConst.INPUT_PASSWORD}
                        type={isRevealPasswordCheck ? "text" : "password"}
                        name="passwordCheck"
                        className="form-control"
                        style={{
                          borderBottomRightRadius: "0.25rem",
                          borderTopRightRadius: "0.25rem",
                        }}
                        value={inputValues.passwordCheck}
                        onChange={handleChangeInputHalfWidth}
                        maxLength={30}
                        data-cy="パスワード確認用テキスト"
                      />
                      <span onClick={togglePasswordCheck} role="presentation">
                        {isRevealPasswordCheck ? (
                          <VscEye
                            size={"1.3rem"}
                            style={{
                              position: "absolute",
                              top: "0.6rem",
                              left: "31rem",
                            }}
                            data-cy="パスワード確認用マスクボタン"
                          />
                        ) : (
                          <VscEyeClosed
                            size={"1.3rem"}
                            style={{
                              position: "absolute",
                              top: "0.6rem",
                              left: "31rem",
                            }}
                            data-cy="パスワード確認用マスク解除ボタン"
                          />
                        )}
                      </span>
                    </div>
                  </div>
                  <div style={{ color: "orange" }}>
                    {tc(
                      "パスワードは10文字以上です。また、英字と数字を必ず含めてください。"
                    )}
                  </div>
                </div>
              )}
              {!passView && status !== statusBrowse && (
                <div className="row">
                  <Button
                    className="b-btn-primary"
                    onClick={setEditPassword}
                    style={{ width: 220, marginLeft: "1rem" }}
                    data-cy="パスワード変更はこちらボタン"
                  >
                    {tc("パスワード変更はこちら")}
                  </Button>
                </div>
              )}
              {passEdit === PASS_EDIT && (
                <div className="row mt-3 mb-3">
                  <Button
                    className="b-btn-light"
                    onClick={setCancelPassword}
                    style={{ width: 220, marginLeft: "1rem" }}
                    data-cy="パスワードの変更をやめるボタン"
                  >
                    {tc("パスワードの変更をやめる")}
                  </Button>
                </div>
              )}
            </div>

            {/* ユーザ画像 */}
            <div className={areaClass}>
              <div className="row  mt-3">
                <div className="col-4">
                  <div
                    style={{ position: "relative", display: "inline-block" }}
                  >
                    <img
                      src={blobURL ?? DEFAULT_PICTURE}
                      alt=""
                      style={{
                        width: 300,
                      }}
                      data-cy="ユーザ画像"
                    />
                    {blobURL &&
                      passEdit !== PASS_EDIT &&
                      !flgView &&
                      renderDeleteButton()}
                  </div>
                </div>

                {/* 自己紹介 */}
                <div className="col-8">
                  <div
                    style={{
                      color: "white",
                      backgroundColor: "#051869",
                      textAlign: "center",
                      padding: "0.375rem 0.75em",
                      height: "30px",
                      borderRadius: "5px 5px 0px 0px",
                    }}
                  >
                    {tc("自己紹介")}
                    {flgBemacUser && passEdit !== PASS_EDIT && !flgView && (
                      <RequireMark />
                    )}
                  </div>
                  <textarea
                    name="introduction"
                    className="form-control textarea b-input-text-square"
                    style={{
                      height: "240px",
                      borderRadius: "0px 0px 5px 5px",
                    }}
                    value={introductionTxt}
                    disabled={flgView}
                    onChange={(event) => setIntroductionTxt(event.target.value)}
                    maxLength={500}
                    placeholder={tc(introductionPlaceholder)}
                    data-cy="自己紹介テキスト"
                  />
                </div>
              </div>
              <div className="row mt-3">
                <input
                  type="file"
                  id="file"
                  accept=".jpg,.png"
                  style={{ display: "none" }}
                  onChange={(e) => selectFile(e)}
                  onClick={(e: any) => {
                    e.target.value = "";
                  }}
                  multiple={true}
                  data-cy="写真ファイル選択"
                />
                {passEdit !== PASS_EDIT && !flgView && (
                  <Button
                    type="button"
                    style={{ width: 150, marginLeft: "1rem" }}
                    className="btn b-btn-primary"
                    onClick={() => document.getElementById("file")?.click()}
                    data-cy="写真を選択ボタン"
                  >
                    {tc("写真を選択")}
                  </Button>
                )}
              </div>
            </div>
          </div>
          {status !== statusBrowse && (
            <div
              className="ag-theme-alpine w-100 mx-auto mt-3 b-grid-outer b-header-row-white"
              data-cy="送付先一覧グリッド"
            >
              <AgGridReact
                domLayout="autoHeight"
                defaultColDef={defaultColDef}
                columnDefs={columnDefsDelivery}
                overlayNoRowsTemplate={t(noRowsText)}
                rowData={deliveryDatas}
                onCellValueChanged={onCellValueChangedDeliveryGrid}
                singleClickEdit
                ref={gridRefDelivery}
              />
            </div>
          )}
          {!flgBemacUser && !tradingFlg && (
            <div className="row mt-3">
              <div className="row">
                <div className="input-group">
                  <span className="sub-title-font-style">
                    {tc("担当船一覧")}
                  </span>
                  {!flgView && (
                    <TooltipIcon
                      messageId={shipListGriTooltip}
                      args={[100, tc("ユーザID")]}
                      data-cy="担当船一覧ツールチップ"
                    />
                  )}
                  <div className=" mx-3">{tc("フィルタ")}</div>
                  <div>
                    <input
                      value={quick}
                      onInput={(e) => onQuickFilterChanged(e)}
                      id="quickFilter"
                      style={{ width: 295, fontSize: 13 }}
                      placeholder={tc(TextConst.LIST_OF_VESSELS_IN_CHARGE)}
                      data-cy="担当船一覧クイックフィルター"
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
          {!flgBemacUser && !tradingFlg && (
            <div className="row">
              <div
                className="ag-theme-alpine mt-1 b-header-row-white"
                style={{
                  width: shipListGridWidth,
                }}
                data-cy="担当船一覧グリッド"
              >
                <AgGridReact
                  domLayout="autoHeight"
                  defaultColDef={defaultColDef}
                  columnDefs={columnDefs}
                  rowData={shipListDatas}
                  rowDragManaged={true}
                  rowSelection="multiple"
                  suppressRowClickSelection
                  ref={gridRef}
                  overlayNoRowsTemplate={t(noRowsText)}
                  onFirstDataRendered={(
                    params: FirstDataRenderedEvent<UserVesselsGetResponse>
                  ) => {
                    if (status !== statusBrowse) {
                      params.api.forEachNode((node) =>
                        node.setSelected(!!node.data && node.data.userSelect)
                      );
                    }
                  }}
                />
              </div>
            </div>
          )}
        </div>
      </>
    );
  }

  // モーダルfooter部レンダリング
  function modalFooter() {
    return (
      <>
        {passEdit !== PASS_EDIT && !flgView && (
          <Button
            className="b-btn-primary m-0"
            onClick={onClickRegistButton}
            data-cy="登録ボタン"
          >
            {tc(registerBtnText)}
          </Button>
        )}
        {(passEdit === PASS_EDIT || passEdit === PASS_CHANGE_MODE) && (
          <Button
            className="b-btn-primary m-0"
            style={{ width: "150px" }}
            onClick={registerPassword}
            data-cy="パスワード変更ボタン"
          >
            {tc("パスワード変更")}
          </Button>
        )}
        <Button
          className="b-btn-close ms-3"
          onClick={handleClose}
          data-cy="Closeボタン"
        >
          Close
        </Button>
      </>
    );
  }

  // レンダリング
  return (
    <Modal
      size="xl"
      show={show}
      onHide={handleClose}
      scrollable
      data-cy="ユーザ詳細モーダル"
    >
      <Modal.Header
        closeButton
        style={{
          backgroundColor: title.includes("BEMAC") ? "" : "#4C525D",
        }}
      >
        <Modal.Title>{tc(title)}</Modal.Title>
      </Modal.Header>

      <Modal.Body>{modalBody()}</Modal.Body>
      <Modal.Footer>{modalFooter()}</Modal.Footer>
    </Modal>
  );
});

export default UserDetail;
