import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import sha256 from "fast-sha256";
import i18next from "i18next";

import ErrorResponse from "../model/api/ErrorResponse";
import {
  ORDER_NO_ALL_ZERO,
  OrderStatus,
  QUOTATION_NO_ALL_ZERO,
  QuotationStatus,
  RecommendStatusClassName,
  RecommendStatusClassValue,
} from "./Constant";

dayjs.extend(timezone);
dayjs.tz.setDefault("Asia/Tokyo");

/** オブジェクトのプロパティ名を動的に指定して値を取り出す */
export function getProperty<T extends object, K extends keyof T>(
  obj: T,
  key: K
): T[K] {
  return obj[key];
}

/** オブジェクトのプロパティ名を動的に指定して値を設定する */
export function setProperty<T extends object, K extends keyof T>(
  obj: T,
  key: K,
  value: T[K]
): void {
  obj[key] = value;
}

/** 見積番号をフォーマット */
export function formatQuotationNo(no: number | string | undefined) {
  if (!no) {
    return "";
  }
  const str = no.toString();
  return (QUOTATION_NO_ALL_ZERO + str).slice(QUOTATION_NO_ALL_ZERO.length * -1);
}

/** 注文番号をフォーマット */
export function formatOrderNo(no: number | string | undefined) {
  if (!no) {
    return "";
  }
  const str = no.toString();
  return (ORDER_NO_ALL_ZERO + str).slice(ORDER_NO_ALL_ZERO.length * -1);
}

/** 見積ステータス名称変換 */
export function convertQuotationStatusName(status: string) {
  switch (status) {
    case QuotationStatus.DRAFT:
      return "作成中";
    case QuotationStatus.REQUESTED:
      return "依頼中";
    case QuotationStatus.ANSWERED:
      return "回答確認中";
    case QuotationStatus.ACCEPTED:
      return "成約";
    case QuotationStatus.DELETED:
      return "取消";
    case QuotationStatus.DENIED:
      return "否成約";
    default:
      return "";
  }
}
/** 注文ステータス名称変換 */
export function convertOrderStatusName(status: string) {
  switch (status) {
    case OrderStatus.DRAFT:
      return "作成中";
    case OrderStatus.ORDERED:
      return "注文中";
    case OrderStatus.ARRANGING:
      return "部品手配中";
    case OrderStatus.REQUESTED_CANCEL:
      return "取消依頼中";
    case OrderStatus.STOPPED_SHIPPING:
      return "取消";
    case OrderStatus.REQUESTED_CHANGE:
      return "否認";
    case OrderStatus.CHANGED:
      return "変更";
    case OrderStatus.DENIED_CHANGE:
      return "停止";
    case OrderStatus.COMPLETED:
      return "完了";
    case OrderStatus.ACCEPTED_LCM:
      return "仮承認";
    default:
      return "";
  }
}

//レコメンドステータスを名称に変換
export function convertRecomendStatusName(
  statusClassValue: string | undefined
) {
  switch (statusClassValue) {
    case RecommendStatusClassValue.DRAFT:
      return RecommendStatusClassName.DRAFT;
    case RecommendStatusClassValue.UNRECOMMENDED:
      return RecommendStatusClassName.UNRECOMMENDED;
    case RecommendStatusClassValue.RECOMMENDED:
      return RecommendStatusClassName.RECOMMENDED;
    case RecommendStatusClassValue.SCHEDULED:
      return RecommendStatusClassName.SCHEDULED;
    case RecommendStatusClassValue.UNIMPLEMENTED:
      return RecommendStatusClassName.UNIMPLEMENTED;
    case RecommendStatusClassValue.COMPLETED:
      return RecommendStatusClassName.COMPLETED;
    default:
      return "";
  }
}

/** SHA-256のハッシュ値に変換 */
export function convertSha256(val: string) {
  // Unit8Arrayに変換
  const arr = new TextEncoder().encode(val);
  // ハッシュ値取得
  const hashedVal = sha256(arr);
  // 16進に変換
  const result = Array.from(hashedVal)
    .map((it) => ("00" + it.toString(16)).slice(-2))
    .join("");
  return result;
}

/** 日付フォーマット（Date | string → string(指定した書式)） */
export function formatDate(
  date: Date | string | undefined | null,
  format?: string
) {
  if (!date) {
    return "";
  }
  if (!format) {
    format = "YYYY/MM/DD";
  }
  if (typeof date === "string") {
    return dayjs(dayjs(date).toDate()).format(format);
  } else {
    return dayjs(date).format(format);
  }
}

export function formatDateBetween(
  fromDate: Date | string | undefined | null,
  toDate: Date | string | undefined | null
) {
  if (!fromDate && !toDate) {
    return "";
  }
  return formatDate(fromDate) + "～" + formatDate(toDate);
}

/** APIの返した業務エラーを取得する */
export function getBizError(e: any): ErrorResponse | undefined {
  const errorHeader = e.response.headers["x-b-msg"];
  if (errorHeader) {
    const json = decodeURIComponent(errorHeader);
    const error = JSON.parse(json) as ErrorResponse;
    return error;
  }
  return undefined;
}

/** メッセージ本文中の{n}をargsで置換する */
export function replaceMessageArgs(baseText: string, args?: any[]) {
  let text = baseText;
  if (args) {
    const replacePoints = text.match(/(\{\d+\})/g);
    if (replacePoints) {
      for (const point of replacePoints) {
        const index = parseInt(point.match(/\d+/)?.[0] || "-1", 10);
        if (index >= 0 && args?.length > index) {
          const after = args[index] ? args[index] : "";
          text = text.replace(point, after);
        }
      }
    }
  }
  return text;
}

/** 言語設定をローカルに保存 */
export function saveLangToLocal(languageClass: "ja" | "en") {
  localStorage.setItem("lang", languageClass);
  i18next.changeLanguage(languageClass);
}

/** 現在の言語設定を取得 */
export function loadCurrentLang() {
  const languageClass = localStorage.getItem("lang") ?? "ja";
  return languageClass;
}

/** 現在の設定が英語かどうか */
export function isEnglish() {
  return loadCurrentLang() === "en";
}

/** 自身のタイムゾーンを取得 */
export function getTimeZone() {
  return Intl.DateTimeFormat().resolvedOptions().timeZone;
}
